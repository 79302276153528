import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { getUser } from "../../utils/utility";
import Footer from "./Footer";
import Navbar from "./NavBar";
import SideDrawer from "./SideDrawer";
import { getItem } from "../../utils/localstorage";
///layout with navbar and footbar
const HeaderFooterLayout = ({ children }) => {
  const router = useRouter();
  const [user, setUser] = useState(null);
  const [showDrawer, setShowdrawer] = useState(false);

  useEffect(() => {
    setUser(getUser());
    setShowdrawer(false);
  }, [router]);

  return (
    <>
      <Navbar setShowdrawer={setShowdrawer} user={user} router={router} />
      {showDrawer ? (
        <SideDrawer
          setShowdrawer={setShowdrawer}
          showDrawer={showDrawer}
          user={user}
          router={router}
        />
      ) : (
        <>
          <main
            className={
              "mx-auto " +
              (["/", "/resources"].includes(router.pathname)
                ? " w-[94vw] sm:w-[86vw]"
                : " min-h-body w-full")
            }
          >
            {" "}
            {children}
          </main>
          {["/about-us", "/", "/faq"].includes(router.pathname) && <Footer />}
          {["/contact-us", "/terms", "/calculator", "/resources"].includes(
            router.pathname
          ) &&
            !user && <Footer />}
        </>
      )}
    </>
  );
};
export default HeaderFooterLayout;
