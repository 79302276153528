import axiosInstance from "../axios.config";

export const emailCheckApi = (payload) => {
  return axiosInstance.post(`auth/send-verification-email`, payload);
};
export const setProfile = (payload) => {
  return axiosInstance.post(`user/set-profile/`, payload);
};
export const emailLogin = (payload) => {
  return axiosInstance.post(`auth/email-login`, payload);
};

export const verifyEmail = (token) => {
  return axiosInstance.post(`auth/verify-email`, token);
};
export const forgotPasssword = (payload) => {
  return axiosInstance.post(`auth/forgot-password`, payload);
};
export const setPasssword = (payload) => {
  return axiosInstance.post(`auth/set-password`, payload);
};
export const getUserDetails = (payload) => {
  return axiosInstance.get(`user/`, payload);
};
export const updateUserDetailsApi = (payload) => {
  return axiosInstance.patch(`user/my-account`, payload);
};
export const getStatesDetailsApi = () => {
  return axiosInstance.get(`states`);
};
export const socailUserApi = () => {
  return axiosInstance.get("auth/getUser");
};
export const generateOtpApi = (payload, token) => {
  return token
    ? axiosInstance.post(`auth/generate-otp`, payload, {
        headers: {
          auth: token,
        },
      })
    : axiosInstance.post(`auth/generate-otp`, payload);
};
export const validateOtpApi = (payload, token) => {
  return token
    ? axiosInstance.post(`auth/validate-otp`, payload, {
        headers: {
          auth: token,
        },
      })
    : axiosInstance.post(`auth/validate-otp`, payload);
};
export const resendOtpApi = (payload) => {
  return axiosInstance.post(`auth/resend-otp`, payload);
};

export const generateCaptchaApi = (payload) => {
  return axiosInstance.post(`auth/generate-captcha`, payload);
};
export const validateCaptchaApi = (payload) => {
  return axiosInstance.post(`auth/validate-captcha`, payload);
};

export const resendCaptchaApi = (payload) => {
  return axiosInstance.post(`auth/resend-captcha`, payload);
};
export const logoutApi = (payload) => {
  return axiosInstance.get(`auth/logout/`, payload);
};
export const userDataApi = () => {
  return axiosInstance.get(`user/meta-data`);
};
export const logutAllSessionsApi = (mobile) => {
  return axiosInstance.delete(`auth/logout-all-devices/${mobile}`);
};
export const deleteUserApi = () => {
  return axiosInstance.delete(`user/`);
};
