import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { getItem } from "../../utils/localstorage";

function Authentication(props) {
  let userDetails;
  const router = useRouter();
  useEffect(() => {
    if (typeof window !== undefined) {
      userDetails = getItem("user");
      if (!userDetails) {
        const path = router.pathname;
        switch (path) {
          case "/":
          case "/loan":
          case "/login":
          case "/verify-email":
          case "/set-password":
          case "/terms":
          case "/contact-us":
          case "/about-us":
          case "/faq":
          case "/session-expired":
          case "/calculator":
          case "/referal":
          case "/404":
          case "/resources":
            break;
          default:
            router.push("/");
        }
      } else if (userDetails) {
        if (router.pathname === "/loan") {
          router.push("/products/loans?loantype=personal");
        } else if (router.pathname === "/login") {
          router.push("/dashboard");
        }
      }
    }
  }, []);
  return <>{props.children}</>;
}

export default Authentication;
