import React from "react";

const CustomButton = ({ buttonType, className, children, ...props }) => {
  const buttomTypeClass = {
    ["pink"]: "bg-button-pink text-white",
    ["white"]: "bg-white",
    ["blue"]: "bg-nav-blue text-white",
    ["outline-blue"]: "border-[1px] border-nav-blue text-nav-blue",
    ["outline-pink"]: "border-[1px] border-button-pink text-button-pink",
  };

  return (
    <button
      className={
        "py-3 button-large rounded sm:rounded-lg " +
        ` ${buttomTypeClass[buttonType]}` +
        ` ${className}`
      }
      {...props}
    >
      {children}
    </button>
  );
};

export default CustomButton;
