import Script from "next/script";
const GoogleAnalytics = ({ ga_id }) => (
  <Script
    strategy="afterInteractive"
    src={`https://www.googletagmanager.com/gtag/js?id=${ga_id}`}
    onLoad={() => {
      const win = window;
      win.dataLayer = win.dataLayer || [];
      function gtag(...args) {
        win.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", ga_id);
    }}
  />
);
export default GoogleAnalytics;
