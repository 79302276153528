import { ACCESS_TOKEN, REFRESH_TOKEN, USER } from "./constants";
import { clear, getItem, setItem } from "./localstorage";
import { alertService } from "./alert.service";

export const validateNumberInput = (input) => {
  let num = /[^0-9]/gi;
  input.target.value = input.target.value.replace(num, "");
};
export const getUser = () => {
  if (typeof localStorage !== "undefined") {
    return JSON.parse(sessionStorage.getItem("user"));
  } else {
    return null;
  }
};
export const emailValidate = (e) => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi.test(
    e
  );
};

export const getTokenIfNotExpired = () => {
  const token = getItem(ACCESS_TOKEN);
  const user = getItem(USER, true);
  if (token && user) {
    const date = new Date();
    const expiredTime = user.exp;
    if (expiredTime < date.getTime() / 1000) {
      clear();
      return null;
    }
  }
  return token;
};
export function calculateLoanFromEMI(emi, rateOfInterest, loanTenureYears) {
  emi = emi >= 1000 ? emi : 1000;
  const monthlyInterestRate =
    rateOfInterest >= 1 ? rateOfInterest / 12 / 100 : 1 / 12 / 100;
  const loanTenureMonths = loanTenureYears >= 1 ? loanTenureYears * 12 : 1 * 12;
  const principal =
    (emi * ((1 + monthlyInterestRate) ** loanTenureMonths - 1)) /
    (monthlyInterestRate * (1 + monthlyInterestRate) ** loanTenureMonths);
  const totalInterest = emi * loanTenureMonths - principal;
  return {
    principal: principal,
    totalInterest: Math.round(totalInterest),
    total: Math.round(principal + totalInterest),
  };
}

export function calculateEMI(principal, interestRate, loanTenure) {
  principal = Number(principal) >= 100000 ? Number(principal) : 100000;
  interestRate =
    Number(interestRate) >= 1 ? Number(interestRate) / 100 : 1 / 100;

  // Calculate monthly interest rate
  const monthlyInterestRate = interestRate / 12;

  // Calculate number of monthly installments
  const numInstallments =
    Number(loanTenure) >= 1 ? Number(loanTenure) * 12 : 1 * 12;
  // Calculate EMI
  let emi =
    (principal *
      monthlyInterestRate *
      Math.pow(1 + monthlyInterestRate, numInstallments)) /
    (Math.pow(1 + monthlyInterestRate, numInstallments) - 1);

  // Calculate total interest
  const totalInterest = emi * numInstallments - principal;

  // Calculate total amount payable
  const totalAmount = principal + totalInterest;

  return {
    emi: Math.round(emi),
    principal: principal,
    totalInterest: Math.round(totalInterest),
    total: Math.round(totalAmount),
  };
}

export const publicKey = `
-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEArS4rgrldg3h79R8Ndq+o
Z2XdGYLS431spLkjgiLbtYRVEGVYshkFUXCIF1duegL8u7MMSxfGQSPYNKcb/UtB
FlcHqzmIp5WeubDXbTGwyqXLAbiW15lFiD3krvVunI4E/uAVCNS7i7VbBdnjerlE
xd+Elvc9rsQbEreNuj2xrefHK6FA77F7rtMBq0PSn8iBlQaHLLR8cGJ2qg55+9hi
oaUT9zj+7+nwF48M406TUaHNLRXdYKW78ASycn+9jdOM1pzq042gyFIgEEHiVGvZ
eakYq6ItJVGXstwDNeGdjDL6VbMQmaxGAiuyC/hENbevPq+Q3GgPdpRzEt2ZJ9w6
4IF+ELW+7mH3ZQFP8Agz959Kyu6QUT2IGCRZX5rnsuc3SNvwXgR4/0SLoCEnsIwv
EPVV6SYkCiFlV21a7Fz5ef6ddd4l+huQVCZeQWP5EE8KXQOo4KPmTks6g8j+SNXm
C/cK+mNV/JcUzk3JnLFVgIQt79EFxp3cv4DMI3QzvY1rn1CjmlrlP5uyVyqhMIpa
8JT/uTxScTx7xqVctdXyHG0DMOcdo8RXShjSZhStkYOtmnzgX8cHXR64Ep1b04vv
V+MjWboWpVa5wtAyn9NWNb8TTUip1rEAeszM2slj/ZC9/zH0Rnk2ijiAxTzp30SG
8ll+oKSVvdXO4aCGtEc7D7MCAwEAAQ==
-----END PUBLIC KEY-----
`;

export const privateKey = `
-----BEGIN RSA PRIVATE KEY-----
MIIJKQIBAAKCAgEAiaBKzLSog3mf6rS4XVEtnjjgJ2Ia08f3yYRrogyLwB6bNzCf
gCDqNDrrDzj/XhkLAS8njeL27xSGovGIS0gB3DHSQsEkfFqhUpo7Cnlln0BH8M/b
OusXhUdJSj3wnx7ab1JAS8GWnzzkXiGuBcOAMdavZk8mw1SprllxcXsxqrgwrDH8
fF9j2kPkDjNj+3jlqvtDbZD9QAxpQanfaIQapzge2ID83DOCogKQ14AqPy+6bIIS
jlAD4EF9iyylk8PRYWVrD2keRdMFDW3iWM33Euoi6yd4GRgfJ0xGuULHaMe/oUbO
o4oNY9ETddKR+bcyTGE9XzerfD7K83CSTOF01L/15ngwGePIG3DiZGUqH8ga7Ti4
/eltPGS6Y91zwmg9bYnwolPtiMABzm/Ex6siN3U8/aCz0uTtA3s5MMORR9Ae6vNf
3H1Hd6LMLPM3WEraocrOx1OGPMiuyHFwi2mgDvjNAyNn2Ou+3nZ9DsBnycXl2fWX
GrX2C4RBUWBZfZSkpIu7H9LPSROIllHVuoiyCkNtOxzhvI7Ovht1AFUmiKT67ru+
x9ozztHsFGs29xH/zvAVxUrHrpAHBPaZeWOVmJulmft1KudnraOB9N7XB8IAyzzq
P1vvcyzPGdJbjtKH5sl/tGeGnba4K486zL00VbA1Sh7xeEgCglrwlZpVn4kCAwEA
AQKCAgBM4VAnnlvsZgrhT+cVqf1zv43oMjOOTsyqs+B0e9GtlutaHz1alAiA+NDK
hiqRZCfzl2XfKwSKdcroR0i91RHE8cflJoO9O9uWizWkGkCcf4owicyL0zwmZbYZ
VRSPruqS+T2VlCUR+zAPLX1LFvUL4kPFQT4EK81F6g0nOYx7M71s09eZmqfrC7Xg
APE/Tz/tDcOS90Whe81osv5sIi/Jbd49T4DxeYzl98z1tVDkT0Myzk6TVTt1EOCS
2HP+YYsbf04ZI72Xf9P4toOU3CnDVSwAdOHwEruEdmWjpBxE+iCgdS+Fx97G/cqD
LQgCmUXtyPxBrpWLKICzERWJAQED7hrdy18bdBipuavvgf2TXkcCPQ+i5VXkAOX9
BBKvGudrlbXM2IA9LheTYBHxw3zeHZ0iNKKuKJqgbCzifN9TMHllq/jqdDKkDgax
3O1ISOGU9gy1O8B0YzYiYl4ae8DsJrsxOXRM1+xIOsBYbX3h+/kVVDVPD6+nMFXx
XZr9xpGpHZsrJrAIjcczRNSj1Y/+0gISRDhUgX85EKcbQNmr4wVhQ7yK+2x3LFzh
LrbTtHx5LiaxXjiubUvAR66pNJguFdqAs6GEmadSRRzra5NjQ8kd2YwlLnFVQVDw
V4cIArGC+qgTh1GZvPw5RpKsBGdkLN1QNmy5iIISxzXXPRruCQKCAQEAwDetgIES
GZm14f4Mr+266b6WMMu9DjkMuPSTAfbyeWtpdbxZQB3PRnCYSMj9KgZd/qbYGqjh
xfEXYnILJLxXVLYodW01O4V4u9ceehsmrfo7m3gtwJyeTZh8q6u2VmXRmCJRpa4p
mzVYy+naQW1onSjgOTLaS1nEhbnTwc/krT7xlrLLzVsX/3xtZtwrgFtosYrrW1Lv
6R7cGf7UG8N52rBc6MgWgi2ax+cN/w0WRe4wwn2KXK8v5w1oYK/c3gQ0Xe00oNYM
+hmrIZs59OfQgihtIo3U1a8QabAgYSuSYMrKB9AsB+4gznCJA0rSRnKSb55f4AnV
AtfGYJr9kbswIwKCAQEAt0s8i8uRjNAqVAteAHgPQH4YxKUFE5Y9zfd6YtpWXKu8
IYIt7g7CHY2QqtPHx/tLaWPCTMRWuperNq7n57z7cqoR4G2J7AjX3VuuWuhwnX4q
+VReBX7z3PPE7FnMGPrGfc15lmmhlFuX8BPjm0EJY4K+7tFlysSeX8nsN8HkadBL
/Sft+NEhBkmo3mOFMxQZwlKcdpCSCUcks8W4tNB1kB4mhXyYs7q4gGsbSpW+UHdH
bFjsHeWcpQQW7rnt8vcA7n1f0J0SzO3ugWdK9VrP/PN5Os6LbXdO17hMDLxb+r3X
g7IXORYcKtJopGJNvRCbhLcZ/kIqIqPdk1COWWUWYwKCAQEAiM+YSFyVu27yTBX8
hcOZRAGI+NYqi9MDQSmi8MUJtG5FM62JgN82xpYhbbzQpHa/tB6gtej6OV7uG/27
/LupFPxKM7o1J1ZKprfoU1S75Ry0DGYvemZ/Lg+YvL+NGlWCJtxWbKxXpMcFmq2p
0OPEkAemAFA3m2YbQGsRU2NbCgxA+9fqwxFRNWEkpQ+UTtnA3I1aEzgm1jBGzKHZ
6qDpPERG/NBdZO58uke0aJKyBbgRMH0WW/29BKFAZ4EEnWk0tJ2+1bNErUZTzamk
SLIucFXBx9RXZngiIHdhtIfEuevcfE8o58QPyZ/DhXpdzBKEM7nv15Bn8owOAHiQ
fodTLQKCAQB137qGJFnr9oriFZVUM+GLFgyTmZQULpB8fmSEYekcG40W94LWD0Oi
gcHBOgTGhMLQeckgWTt1L8h5mC2nCA+kg96PN6J02pMH1cfgyo5xmgvE4A6+9g+Z
zmKbpHvurIctrBA/PJser3Ccgg3jcAW7qv9i0eLmbV90NoTkrppXug7ENQOC+eqF
AqXIrrOgT5LpNvtL/FkOAmSF/+dy0IRqNBmNyrzhCHlSjI62fmovkSWrddwStfDw
5hphV8zAbTx6IsKVhA3tIC47pdO8ybPr9h/GQ8HCmfFoJSszHYcBT0jjdoocui1P
FJUa8eUQaze4N5BNxPsX383EyQ9iSfIFAoIBAQCMEX2mu/htt5AJEun7hap8XiT1
++B0zv2nFvz9tryxUbmh58XF1KsC0LCPvgC9l+SQdY9UPJYK4s5bOTRgs/SKuiC8
liQ/x9bl4Xln7LVWqsEvEYT3EfsQmCd5G9xskZxOefl9w/PPFr/zhXZ76XjWyF6u
juOgcwRaPAyr7C+VbsBPQqwINhDWcdLOd1YHLDVQs6MxIDLPcTYZle7FPp6O7jNs
va8Ch7hJMeH27AxHap+Igd1F9+WHuiu7YBn+Ldr6hk7OTXBgGaj/lq+FKwHeSK4M
Hb3gxQJxRPMBvf7fMi0wNunnjBT8vfEvVblrulIJ9mNBbEd9bcABbzcMrYyt
-----END RSA PRIVATE KEY-----
`;

export const modifiedName = (str) => {
  const [first, ...rest] = str.split(" ");
  return first.charAt(0).toUpperCase() + first.slice(1);
};
export const loginSuccess = (resp, router) => {
  setItem(USER, resp, true);
  setItem(ACCESS_TOKEN, resp?.access_token);
  setItem(REFRESH_TOKEN, resp?.refresh_token);
  sessionStorage.setItem("loggedin", true);
  alertService.success("Logged in Successfully!!", {
    keepAfterRouteChange: true,
  });
  resp?.is_set_profile ? router.push("/dashboard") : router.push("/setprofile");
};

export const modifiedDateTime = (data) => {
  const dateString = data;
  const date = new Date(dateString);

  // Format date part (YYYY-MM-DD)
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${day}-${month}-${year}`;

  // Format time part (HH:MM:SS AM/PM)
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = String(hours % 12 || 12).padStart(2, "0");
  const formattedTime = `${formattedHours}:${minutes}:${seconds} ${ampm}`;

  return `${formattedDate} ${formattedTime}`;
};

export const modifiedDate = (date) => {
  const dateString = new Date(date);

  // Format date part (YYYY-MM-DD)
  const year = dateString.getFullYear();
  const month = String(dateString.getMonth() + 1).padStart(2, "0");
  const day = String(dateString.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return `${formattedDate}`;
};

export const checkLoanTypeFromId = (id) => {
  let type = id?.substring(0, 2);
  switch (type) {
    case "HL":
      return "Home";
    case "PL":
      return "Personal";
    case "BL":
      return "Business";
    default:
      return "Personal";
  }
};
