import Image from "next/image";
import React from "react";
import SideBoy from "../public/images/Referal/ReferandEarnmore.png";
import Speaker from "../public/images/Referal/ReferaFriend.png";
import Hero from "../public/images/Referal/HeroSectionVector.png";
import { useRouter } from "next/router";
import CustomButton from "../components/CustomComponents/CustomFormElements/CustomButton";
import Contact from "../public/images/Referal/Contact.png";
import Footer from "../components/Layout/Footer";
import ReferalForm from "../components/forms/referral/ReferalForm";

function Referal() {
  const router = useRouter();
  return (
    <>
      <div>
        <div className="grid grid-cols-1 items-center justify-center gap-3 bg-gradient-to-b from-[#F2B3C9] to-[#f2b3c900] py-3 sm:h-[400px] sm:grid-cols-2 sm:gap-14 sm:py-9">
          <Image
            src={Hero}
            alt="Hero"
            className="h-[200px] w-auto place-self-center sm:h-full"
          />
          <div className="justify-self-start text-center">
            <p className="mb-3 text-[32px] font-bold leading-8 text-button-pink sm:mb-6 sm:text-6xl sm:leading-[60px]">
              Fingo Mega Loan Mela
            </p>
            <p className="leading 8 text-xl font-bold text-nav-blue  sm:text-[28px]">
              Personal Loan, Business Loan, Home Loan,
              <br />
              Vehicle Loan and Education loan
            </p>
            <p className="pt-6 text-2xl font-bold sm:text-4xl">
              Sep 9th - Oct 9th
            </p>
            <p className="text-text-grey  mx-auto w-4/5 text-base  font-normal sm:w-3/4 sm:text-xl sm:font-normal">
              Get chance to win exciting rewards for Both Referral and Referrer
            </p>
          </div>
        </div>
        <div className="mx-auto mt-9 w-[94vw] sm:mt-0 sm:w-[86vw]">
          <div className="text-center">
            <p className="pt-0 text-2xl font-bold leading-6 text-nav-blue sm:pt-16 sm:text-5xl sm:leading-10">
              Refer a Friend and Together Earn Upto
            </p>
            <br />
            <p className="text-5xl font-bold leading-[48px] text-button-pink  sm:text-6xl sm:leading-[60px]">
              ₹5750/
              <span className="text-sm font-bold font-semibold text-black sm:text-[36px]">
                Referral
              </span>
            </p>
            <CustomButton
              buttonType={"pink"}
              className={"my-5 px-12 py-[6px]"}
              onClick={() => router.push("#form")}
            >
              Refer Now
            </CustomButton>
          </div>
          <div className="mt-12 grid grid-cols-2 items-center justify-center gap-3 sm:mt-6 sm:grid-cols-3 sm:pb-6">
            <p className="w-full text-left text-lg leading-6 sm:text-2xl">
              Refer a friend and earn up to
              <br />
              <span className="h3 text-button-pink">₹750</span> for each
              successful <br /> referral
            </p>

            <Image
              src={Speaker}
              alt="Speaker"
              className="row-span-2 h-auto w-full justify-self-center sm:row-span-1 sm:h-full sm:w-auto"
            />
            <div className="text-left sm:text-right">
              <p className="w-full pt-6 text-left text-base leading-6 sm:text-right sm:text-2xl">
                Get Cashback up-to{" "}
                <span className="text-xl font-bold text-text-blue sm:text-4xl">
                  ₹5,000
                </span>
                <br />
                on your processing charges
              </p>
              <p className="text-sm font-normal sm:text-xl ">
                Referee will be awarded up to 100% of
                <br />
                Processing charges
              </p>
              <p className="body5 text-xs text-nav-black sm:mt-3">
                *Excluding GST/other charges
              </p>
            </div>
          </div>
          <div className="mx-auto mt-9 grid w-[100%] grid-cols-1 justify-between gap-3 sm:mt-0 sm:grid-cols-2 sm:gap-[120px]">
            <table className="border-collapse rounded-lg border border-black text-center sm:w-[100%]">
              <tbody>
                <tr>
                  <th colSpan={2} className="h4 bg-button-pink text-white">
                    For Referrer
                  </th>
                </tr>
                <tr>
                  <th className="border border-nav-black text-base font-normal font-bold sm:text-2xl	">
                    Number of Referrals
                  </th>
                  <th className="border border-nav-black text-base font-normal font-bold sm:text-2xl	">
                    Rewards
                  </th>
                </tr>
                <tr>
                  <td className="border border-nav-black text-base font-normal sm:text-2xl">
                    1-2
                  </td>
                  <td className="border border-nav-black text-base font-normal sm:text-2xl">
                    ₹500 / Referral
                  </td>
                </tr>
                <tr>
                  <td className="border border-nav-black text-base font-normal sm:text-2xl">
                    3-5
                  </td>
                  <td className="border border-nav-black text-base font-normal sm:text-2xl">
                    ₹600 / Referral
                  </td>
                </tr>
                <tr>
                  <td className="border border-nav-black text-base font-normal sm:text-2xl">
                    6 and Above
                  </td>
                  <td className="border border-nav-black text-base font-normal sm:text-2xl">
                    ₹750 / Referral
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="border-collapse border border-black text-center  sm:w-[100%]">
              <tbody>
                <tr>
                  <th colSpan={2} className="h4 bg-nav-blue p-2 text-white">
                    For Referee
                  </th>
                </tr>
                <tr>
                  <th className="border border-nav-black text-lg font-bold font-bold   sm:text-2xl">
                    Loan Amount
                  </th>
                  <th className="border border-nav-black text-lg font-bold font-bold  sm:text-2xl">
                    Cash Back on Processing Fee
                  </th>
                </tr>
                <tr>
                  <td className="border border-black text-base font-normal sm:text-2xl">
                    ₹2-5 Lakhs
                  </td>
                  <td className="border border-black text-base font-normal sm:text-2xl">
                    50%
                  </td>
                </tr>
                <tr>
                  <td className="border border-black text-base font-normal sm:text-2xl">
                    ₹5-10 Lakhs
                  </td>
                  <td className="border border-black text-base font-normal sm:text-2xl">
                    70%
                  </td>
                </tr>
                <tr>
                  <td className="border border-black text-base font-normal sm:text-2xl">
                    ₹10-15 Lakhs & Above
                  </td>
                  <td className="border border-black text-base font-normal sm:text-2xl">
                    100%
                  </td>
                </tr>
                <tr></tr>
              </tbody>
            </table>
          </div>
          <div className="mt-3 text-center sm:mx-auto sm:mt-8 ">
            <p className="text-text-grey text-sm font-semibold sm:text-2xl sm:font-bold ">
              The program is applicable for securing
              <br />
              <span className="text-base font-bold text-button-pink sm:text-2xl sm:font-bold">
                Personal Loan, Business Loan, Home Loan, 4 Wheeler Vehicle Loan
                and Education loan
              </span>
              <br />
              from our partner banks and NBFCs
            </p>
            <p className="body5 text-nav-blue sm:mt-3">
              *Terms & Conditions applieds
            </p>
          </div>
          <div className="ml-[-2.3vw] mt-3 hidden w-[100vw] bg-button-pink text-center text-xl text-white sm:ml-[-7.6vw] sm:mt-12 sm:block sm:h-[200px] sm:text-2xl">
            <div className="py-12">
              <p
                className="text-xl
               font-normal sm:mb-3 sm:text-4xl sm:font-bold "
              >
                So, what are you waiting for?
                <br />
                Call/WhatsApp Us @{" "}
                <span className=" text-2xl font-bold sm:text-4xl">
                  +91 90009 22519.
                </span>{" "}
                Share the Details
              </p>
              <p classNme="text-2xl  font-medium">
                Our team will take care of rest. Promise🤞.
              </p>
            </div>
          </div>
          <div className="ml-[-2.3vw] mt-3 w-[100vw] bg-button-pink text-center text-xl text-white sm:ml-[-7.6vw] sm:mt-12 sm:hidden sm:h-[200px] sm:text-2xl">
            <div className="py-12">
              <p
                className="text-xl
               font-normal sm:mb-3 sm:text-4xl sm:font-bold "
              >
                So, what are you waiting for?
                <br />
                Call/WhatsApp Us
                <br /> @{" "}
                <span className=" text-2xl font-bold sm:text-4xl">
                  +91 90009 22519.
                </span>
                <br />
                Share the Details
              </p>
              <p className="pt-3 text-2xl font-medium">
                Our team will take care of rest.
                <br />
                Promise🤞.
              </p>
            </div>
          </div>
          <div className="mt-6 text-center sm:mt-12">
            <p className="h1 mb-3 text-center text-nav-blue  sm:text-6xl  sm:font-bold">
              Refer More & Earn More
            </p>
            <div className="relative ml-auto w-3/4 border border-[#D60B52] p-[18px] sm:mx-auto sm:mt-6 sm:h-auto sm:w-[375px] sm:rounded-lg sm:p-6">
              <p className="text-base font-normal sm:mb-3 sm:text-2xl">
                Amazon voucher worth
              </p>
              <p className="mt-2 text-5xl font-bold leading-[48px] text-button-pink sm:mb-3 sm:mt-3 sm:text-6xl sm:leading-[60px]">
                *₹750/
                <span className="text-sm font-bold text-black sm:text-2xl">
                  Referral
                </span>
              </p>
              <p className="mt-2 text-xs font-normal text-text-gray sm:mb-6 sm:mt-3 sm:text-base">
                Extended to the referrer upon the successful disbursal of any
                loan to his/ her referee.
              </p>
              <div className="mt-4 flex items-center sm:mt-6">
                <div className="h-px flex-grow bg-nav-blue"></div>
                <span className="flex-shrink px-2 text-2xl">+</span>
                <div className="h-px flex-grow bg-nav-blue"></div>
              </div>

              <p className="mt-4 text-sm font-normal text-text-gray sm:mt-6 sm:text-xl">
                Cashback up to
                <span className="font-bold text-nav-blue"> 100%</span> of
                Processing charges/Referee
              </p>
              <Image
                src={SideBoy}
                alt="SideBoy"
                className="absolute left-[-98px] top-0"
              />
            </div>
            <p className="mx-auto mb-5 mt-2 text-text-gray sm:mt-3 sm:w-[375px] sm:px-2">
              *The voucher will be credited within 10 working days after the
              successful transaction
            </p>
          </div>
          <ReferalForm />
          <div className="mb-6 sm:mt-[56px] sm:grid sm:grid-cols-2 sm:px-6">
            <p className="text-center text-3xl font-bold sm:text-4xl">
              Contact Us
              <br />
              <span className="body1 mt-3 flex items-center justify-center text-nav-blue">
                <Image
                  src={Contact}
                  alt="Contact"
                  className="mr-3 h-auto w-8 sm:w-6"
                />
                +91 90009 22519
              </span>
            </p>
            <div className="mt-6 flex flex-col items-center justify-center gap-3 sm:mt-0 sm:flex-row">
              <p className="body2">Want personalised help?</p>
              <CustomButton
                buttonType="blue"
                className={"px-[86px] sm:px-[58px]"}
                onClick={() => router.push("/login")}
              >
                Sign In
              </CustomButton>
            </div>
          </div>
          <div className="w-full border-b-[1px] border-black" />
          <div className="mt-6 flex flex-col items-center">
            <p className="mb-3 text-2xl font-bold text-nav-blue">
              Terms & Conditions*{" "}
            </p>
            <ul className="body5 list-disc pl-6 text-text-gray sm:w-3/4">
              <li>
                The referee/referral has to share the details of
                referral/referee to enjoy the above offers within 3 days from
                the date of logging in case.
              </li>
              <li>
                The cashback/rewards would be paid only for successful disbursal
                of loan from our partner banks only.
              </li>
              <li>
                Only one referrer is accepted for each referee, in case there
                are multiple referrers referring the same referee, we will take
                the confirmation from referee on whose referral to be
                considered. In case, of any dispute, the decision of Fingo shall
                be final on all parties without any recourse to disputes.
              </li>
              <li>
                Fingo holds back the right to, without any liability, penalty or
                prejudice to any of its other rights, at any time, without
                previous notice, withdraw/suspend/amend/cancel this Offer, with
                or without any reason whatsoever.
              </li>
              <li>
                Vouchers for the referrer are capped at up to 25 referrals per
                individual during the validity of the program.
              </li>
              <li>
                For referee, the cash back on processing charge is limited to a
                maximum of Rs. 5,000 per each case
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default Referal;
