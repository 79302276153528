import Link from "next/link";
import Image from "next/image";
import { Fragment } from "react";
import { useRouter } from "next/router";

const DrawerMenu = ({ data }) => {
  const router = useRouter();
  return (
    <>
      <ul className="space-y-3">
        {data?.map((item) => {
          let kidsHref = item?.kids?.find(
            (d) => d.href === router.asPath
          )?.href;
          return !item?.kids ? (
            <Fragment key={item?.title}>
              <li key={item?.title} className="!mt-0">
                <Link
                  target={
                    [
                      "/about-us",
                      "/contact-us",
                      "/faq",
                      "/terms",
                      "/resources?type=personal#",
                      "/resources?type=business#",
                      "/resources?type=home#",
                    ].includes(item?.href)
                      ? "_blank"
                      : "_self"
                  }
                  href={item?.href}
                  className={
                    "flex items-center rounded-lg text-sm  text-gray-900 sm:text-base sm:font-medium" +
                    (item?.src
                      ? " border-b-[1px] p-3 text-base sm:border-none sm:!font-semibold"
                      : " p-[6px] !text-[#475569]") +
                    (item?.disable ? " pointer-events-none" : "") +
                    (item?.href === router.asPath ? " sm:bg-blue-100" : "")
                  }
                >
                  <div className={`${item?.body ? "" : "flex items-center"}`}>
                    {item?.src && (
                      <Image
                        src={
                          item?.href === router.asPath
                            ? item?.srcActive
                            : item?.src
                        }
                        alt={item?.src}
                        className="h-[18px] w-[18px] lg:h-[18px] lg:w-[18px]"
                      />
                    )}
                    <span className={`${item?.body ? "font-black" : "ml-3"}`}>
                      {item?.title}
                    </span>
                  </div>
                </Link>
              </li>
              <div className="font-light">{item?.body}</div>
            </Fragment>
          ) : (
            <div key={item?.title} id={item?.title} className="!mt-0">
              <details
                open
                className={
                  "group w-full rounded-lg p-3 text-sm text-gray-900 transition duration-75 sm:text-base sm:font-semibold" +
                  (item?.src
                    ? " border-b-[1px] p-3 text-base sm:border-none sm:!font-semibold"
                    : " p-[6px]")
                }
              >
                <summary className="my-2 flex items-center justify-between">
                  {item?.src && (
                    <Image
                      src={kidsHref ? item?.srcActive : item?.src}
                      alt={item?.src}
                      className={"h-[20px] w-[20px] lg:h-[18px] lg:w-[18px]"}
                    />
                  )}
                  <span
                    className={
                      "ml-3 flex-1 whitespace-nowrap text-left" +
                      (kidsHref ? " text-nav-blue " : " ")
                    }
                  >
                    {item?.title}
                  </span>
                </summary>
                <DrawerMenu data={item?.kids} />
              </details>
            </div>
          );
        })}
      </ul>
    </>
  );
};
export default DrawerMenu;
