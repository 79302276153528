import Image from "next/image";
import React, { useState } from "react";
const CustomInputField = ({
  Controller,
  control,
  customOnChange,
  defaultOnChange,
  errors,
  fieldKey,
  fieldName,
  customDataType,
  labelIcon,
  infoText,
  minLength,
  maxLength,
  notRequired,
  pattern,
  placeholder,
  prefix,
  suffix,
  onBlur,
  requiredError,
  disabled = false,
}) => {
  const [showInfo, setShowInfo] = useState(false);
  const isValidate = (e) => {
    if (/^\d+$/.test(e)) {
      return e.length !== 10 ? `Enter valid phone number` : null;
    } else if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi.test(
        e
      )
    ) {
      return `Enter valid email address`;
    } else {
      return null;
    }
  };

  //console.log(errors);
  return (
    <div className="my-2 w-full text-sm sm:text-base">
      <div className="flex">
        <p className="mb-2 text-base" htmlFor={fieldKey}>
          {fieldName}
        </p>
        {labelIcon && (
          <div className="group relative w-auto text-xs text-[#167FFC]">
            <Image
              alt="labelIcon"
              src={labelIcon}
              // icon={labelIcon}
              className="ml-2 "
              size="lg"
              onClick={() => setShowInfo(!showInfo)}
            />
            {infoText && (
              <div
                className={
                  `!group-hover:block absolute bottom-10 left-0 mb-2 hidden w-fit w-[50vw] rounded-lg bg-[#fff] py-2 px-10 shadow-lg sm:right-0 sm:w-[15vw] ${
                    showInfo ? " !block" : " !hidden sm:group-hover:!block"
                  }` + (infoText ? " left-0 sm:right-0" : " ")
                }
                htmlFor={fieldKey}
              >
                <div
                  class={
                    ` absolute bottom-[-9px] h-0 w-0 border-l-[10px] border-t-[10px] border-r-[10px] border-l-transparent border-t-[#fff] border-r-transparent sm:bottom-[-10px] ${
                      showInfo ? " !block" : " !hidden sm:group-hover:!block"
                    }` + (infoText ? " left-4 sm:right-4" : " ")
                  }
                ></div>
                {infoText}
              </div>
            )}
          </div>
        )}
      </div>

      <Controller
        name={fieldKey}
        control={control}
        rules={{
          required: notRequired ? false : true,
          minLength: minLength,
          maxLength: maxLength,
          autoComplete: false,
          pattern:
            pattern === "email"
              ? /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi
              : pattern === "panCard"
              ? /[A-Z]{5}[0-9]{4}[A-Z]{1}/
              : pattern === "fullName"
              ? new RegExp("^[a-zA-Z '-]+$")
              : pattern === "password" &&
                new RegExp(
                  "^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
                ),
          validate: customDataType === "emailOrPhone" && isValidate,
        }}
        defaultValue={""}
        render={({ field }) => (
          <div
            className={
              "text-md flex h-[48px] w-full rounded-lg border-[1px] p-3" +
              (errors?.[`${fieldKey}`]
                ? " border-[1px] border-red-500 focus:border-red-500 focus:outline-none"
                : " border-[#CBD5E1]")
            }
          >
            {prefix && (
              <span className="h-full border-r-2 border-[#EAEAEA] pr-2">
                {prefix}
              </span>
            )}
            <input
              type={customDataType === "password" ? customDataType : null}
              className={
                "h-full w-full border-0 text-base focus:outline-none" +
                (prefix ? " ml-2" : " ")
              }
              {...field}
              disabled={disabled}
              aria-invalid={errors?.[`${fieldKey}`] ? "true" : "false"}
              placeholder={placeholder ? placeholder : `Enter ${fieldName}`}
              onChange={(e) => {
                if (customDataType == "number") {
                  field.onChange(e.target.value.replace(/[^0-9]/gi, ""));
                  customOnChange && customOnChange(e);
                } else if (customDataType == "char") {
                  field.onChange(e.target.value.replace(/[^a-zA-Z ]/gi, ""));
                } else if (pattern === "panCard") {
                  field.onChange(e.target.value.toLocaleUpperCase());
                } else if (customOnChange) {
                  defaultOnChange && field.onChange(e);
                  customOnChange(e);
                } else if (fieldKey === "address") {
                  field.onChange(e);
                } else {
                  fieldKey !== "email"
                    ? field.onChange(
                        e.target.value.replace(/[^0-9a-zA-Z ]/gi, "")
                      )
                    : field.onChange(e.target.value?.toLocaleLowerCase());
                }
              }}
              onBlur={(e) => {
                onBlur ? onBlur(e) : field.onBlur(e);
              }}
              autocomplete={"off"}
            />
            {suffix && (
              <span className="h-full border-l-2 border-[#EAEAEA] pl-4">
                {suffix}
              </span>
            )}
          </div>
        )}
      />
      {errors?.[`${fieldKey}`]?.type === "pattern" &&
        fieldKey === "full_name" && (
          <p role="alert" className="mt-2 text-red-500">
            {requiredError
              ? requiredError
              : `${fieldName} should not contain special characters`}
          </p>
        )}
      {errors?.[`${fieldKey}`]?.type === "required" && (
        <p role="alert" className="mt-2 text-red-500">
          {requiredError
            ? requiredError
            : ` ${fieldName.toLocaleLowerCase()} required`}
        </p>
      )}
      {(fieldKey === "mobile" || fieldKey === "emailOrPhone") &&
        (errors?.[`${fieldKey}`]?.type === "minLength" ||
          errors?.[`${fieldKey}`]?.type === "maxLength") && (
          <p role="alert" className="mt-2 text-red-500">
            please enter 10-digit {fieldName.toLocaleLowerCase()}
          </p>
        )}
      {errors?.[`${fieldKey}`]?.type === "minLength" &&
        fieldKey === "pan_card" && (
          <p role="alert" className="mt-2 text-red-500">
            Oops! Looks like your PAN card number is too short. Please enter a
            valid PAN card number.
          </p>
        )}
      {errors?.[`${fieldKey}`]?.type === "maxLength" &&
        fieldKey === "pan_card" && (
          <p role="alert" className="mt-2 text-red-500">
            Oops! Looks like your PAN card number is too long. Please enter a
            valid PAN card number.
          </p>
        )}
      {(fieldKey === "captcha" || fieldKey === "pin_code") &&
        (errors?.[`${fieldKey}`]?.type === "minLength" ||
          errors?.[`${fieldKey}`]?.type === "maxLength") && (
          <p role="alert" className="mt-2 text-red-500">
            please enter 6-digit {fieldName}
          </p>
        )}

      {errors?.[`${fieldKey}`]?.type === "minLength" &&
        fieldKey !== "pin_code" &&
        fieldKey !== "pan_card" &&
        fieldKey !== "mobile" &&
        fieldKey !== "captcha" &&
        fieldKey !== "emailOrPhone" && (
          <p role="alert" className="mt-2 text-red-500">
            {fieldName.toLocaleLowerCase()} must be greater than {minLength - 1}{" "}
            characters
          </p>
        )}
      {errors?.[`${fieldKey}`]?.type === "maxLength" &&
        fieldKey !== "pan_card" &&
        fieldKey !== "pin_code" &&
        fieldKey !== "mobile" &&
        fieldKey !== "captcha" &&
        fieldKey !== "emailOrPhone" && (
          <p role="alert" className="mt-2 text-red-500">
            {fieldName.toLocaleLowerCase()} must be less than {maxLength + 1}{" "}
            characters
          </p>
        )}

      {fieldKey === "email" && errors?.[`${fieldKey}`]?.type === "pattern" && (
        <p role="alert" className="mt-2 text-red-500">
          Enter valid {fieldName.toLocaleLowerCase()}
        </p>
      )}
      {fieldKey === "pan_card" &&
        errors?.[`${fieldKey}`]?.type === "pattern" && (
          <p role="alert" className="mt-2 text-red-500">
            please enter a valid PAN number
          </p>
        )}

      {fieldKey === "password" &&
        errors?.[`${fieldKey}`]?.type === "pattern" && (
          <p role="alert" className="mt-2 text-red-500">
            Use 8 or more characters with a mix of lowercase and uppercase
            letters, numbers & symbols !
          </p>
        )}
      {errors?.[`${fieldKey}`]?.type === "validate" && (
        <p role="alert" className="mt-2 text-red-500">
          {errors?.[`${fieldKey}`]?.message}
        </p>
      )}
      {errors?.[`${fieldKey}`]?.type === "custom" && (
        <p role="alert" className="mt-2 text-red-500">
          {errors?.[`${fieldKey}`]?.message}
        </p>
      )}
    </div>
  );
};
export default CustomInputField;
