import axios from "axios";
import { clear, getItem, setItem } from "./utils/localstorage";
import { ACCESS_TOKEN, REFRESH_TOKEN, USER } from "./utils/constants";
import { alertService } from "./utils/alert.service";
import { getTokenIfNotExpired, publicKey, privateKey } from "./utils/utility";
import { JSEncrypt } from "nodejs-jsencrypt";
import { v4 as uuidv4 } from "uuid";
import CryptoJS from "crypto-js";
const secretKey = uuidv4();

const axiosConfig = {
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};
const axiosInstance = axios.create(axiosConfig);

var encrypt = new JSEncrypt();
encrypt.setPublicKey(publicKey);
var decrypt = new JSEncrypt();
decrypt.setPrivateKey(privateKey);

axiosInstance.interceptors.request.use((config) => {
  const token = getTokenIfNotExpired();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (config.data) {
    // Check if data has already been encrypted
    const hasEncryptedHeader = Object.keys(config.headers).some(
      (key) => key.toLowerCase() === "x-encrypted-data"
    );

    if (!hasEncryptedHeader) {
      const data = CryptoJS.AES.encrypt(
        JSON.stringify(config.data),
        secretKey
      ).toString();
      config.headers["X-Encrypted-Data"] = "true";
      config.data = { data, key: encrypt.encrypt(JSON.stringify(secretKey)) };
    }
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    const data = JSON.parse(
      CryptoJS.AES.decrypt(
        response?.data?.data,
        decrypt.decrypt(response?.data?.key)
      ).toString(CryptoJS.enc.Utf8)
    );
    response.data = data;
    console.log(response);
    return response;
  },

  async (error) => {
    console.log("error", error, typeof error);
    const curPage = window.location.pathname;
    const refreshTokenApi = "/auth/new-access-token/";
    const originalRequest = error.config;
    const errorMessage = error.response.data.code;
    const errorStatusCode = error.response.status;
    const tokenInvalid = "token_not_valid";
    const expiredToken = "TokenExpiredError jwt expired";
    const invalidCred = "invalid_credentials";
    const accountNotFound = "user_not_found";

    // Prevent infinite loops
    if (errorStatusCode === 401 && originalRequest.url === refreshTokenApi) {
      clear();
      window.location.href = "/";
      return Promise.reject(error);
    }

    //Invalid credentials or user not exist
    if (
      (errorMessage === invalidCred || errorMessage === accountNotFound) &&
      curPage !== "/login" &&
      errorStatusCode === 401
    ) {
      clear();
      window.location.href = "/login";
    }

    //triggers when user session is expired
    if (
      (error.response.data.message === expiredToken ||
        errorMessage === tokenInvalid) &&
      errorStatusCode === 401
    ) {
      const refreshToken = getItem(REFRESH_TOKEN);
      const loginId = getItem("loginId");
      if (refreshToken) {
        const regex = new RegExp(
          "^[A-Za-z0-9-_=]+.[A-Za-z0-9-_=]+.?[A-Za-z0-9-_.+/=]*$"
        );

        if (regex.test(refreshToken)) {
          const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

          // exp date in token is expressed in seconds, while now() returns milliseconds:
          const now = Math.ceil(Date.now() / 1000);

          //triggers if refresh token is not expired
          if (tokenParts.exp > now) {
            return axiosInstance
              .post(refreshTokenApi, {
                refresh_token: refreshToken,
                loginId: loginId,
              })
              .then((response) => {
                setItem(ACCESS_TOKEN, response.data.access_token);
                return axiosInstance(originalRequest);
              })
              .catch((error) => console.log(error));
          } else {
            clear();
            window.location.href = "/";
            // alertService.success("Logged in Successfully!!", {
            //   keepAfterRouteChange: true,
            // });
            alertService.error(
              "Your session has been expired, please login again",
              {
                keepAfterRouteChange: true,
              }
            );
          }
        } else {
          clear();
          window.location.href = "/";
        }
      } else {
        clear();
        window.location.href = "/";
        alertService.error(
          "Your session has been expired, please login again",
          {
            keepAfterRouteChange: true,
          }
        );
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;
