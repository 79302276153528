import React from "react";
import Link from "next/link";
import Image from "next/image";
import FooterFingo from "../../public/images/footerImages/FooterFingo.png";
import iso from "../../public/images/footerImages/iso.png";
import { fingoSocialLinks } from "../../utils/constants";
import Referal from "../../pages/referal";
import CustomButton from "../CustomComponents/CustomFormElements/CustomButton";
import { useRouter } from "next/router";

const Footer = () => {
  const router = useRouter();
  const FooterList = [
    { name: "About Us", href: "/about-us" },
    { name: "FAQs", href: "/faq" },
    { name: "Terms of Use", href: "/terms#Terms of Use" },
    { name: "Privacy Policy", href: "/terms#Privacy Policy" },
    { name: "Contact Us", href: "/contact-us" },
  ];
  return (
    <footer className="mt-10 bg-nav-blue py-3 text-white sm:pt-6 sm:pb-4">
      <div className="mx-auto w-[94vw] sm:w-[86vw]">
        <div className="grid grid-cols-2">
          <Link href="/" className="py-5">
            <Image src={FooterFingo} alt="logo" className="h-[23px] w-auto" />
          </Link>
          <div className="flex gap-6 place-self-end self-center">
            {fingoSocialLinks.map((item) => {
              return (
                <Link
                  className="rounded-full bg-[#fafafa1a] p-1 sm:p-2"
                  key={item.name}
                  href={item.href}
                  target="_blank"
                >
                  <Image
                    src={`/images/footerImages/${item.name}.svg`}
                    width={20}
                    height={20}
                    alt="logo"
                    className="h-[12px] w-auto sm:h-[21px]"
                  />
                </Link>
              );
            })}
          </div>
        </div>
        <div className="grid grid-cols-5 items-center">
          <div className=" col-span-4 mt-6 grid grid-cols-3 items-center gap-y-3 text-xs sm:my-6 sm:flex sm:gap-6 sm:text-base sm:font-semibold">
            {FooterList.map((list, index) => (
              <Link
                href={list.href}
                key={list.name}
                className={list.disable ? " pointer-events-none" : ""}
              >
                {list.name}
              </Link>
            ))}

            <CustomButton
              buttonType={"pink"}
              className={" py-1.5 !text-lg font-bold sm:block sm:px-6"}
              onClick={() => router.push("/referal")}
            >
              Refer a friend
            </CustomButton>
          </div>

          <Image
            src={iso}
            alt="iso"
            className="col-span-1 h-[32px] w-[32px] justify-self-center sm:h-auto sm:w-[50px] sm:justify-self-end"
          />
        </div>
        <div className="body4 hidden gap-3 text-[#D9DBE1] sm:flex">
          <Link href="mailto:customercare@fingoindia.com">
            customercare@fingoindia.com
          </Link>
          <p> | </p>
          <Link href="tel:9000922519">+91 90009 22519</Link>
        </div>
        <div className="my-3 ml-[-2vw] border-b-[1px] border-[#BABABA] sm:w-[90vw]"></div>
        <p className="text-center text-xs text-[#D9DBE1] sm:text-sm">
          © {new Date().getFullYear()} Fingo. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
