export const setItem = (key, value, isObject = false) => {
  const data = isObject ? JSON.stringify(value) : value;
  sessionStorage?.setItem(key, data);
};

export const getItem = (key, isObject = false) => {
  const value =
    typeof sessionStorage !== "undefined" && sessionStorage?.getItem(key);
  const data = isObject ? JSON.parse(value) : value;
  return data;
};
export const removeItem = (key) => {
  sessionStorage.removeItem(key);
};
export const clear = () => {
  sessionStorage.clear();
};
