import { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";

import { alertService, AlertType } from "../../utils/alert.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnchorCircleXmark,
  faCircleCheck,
  faCircleXmark,
  faClose,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";

export default Alert;

Alert.propTypes = {
  id: PropTypes.string,
  fade: PropTypes.bool,
};

Alert.defaultProps = {
  id: "default-alert",
  fade: true,
};

function Alert({ id, fade }) {
  const mounted = useRef(false);
  const router = useRouter();
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    mounted.current = true;

    // subscribe to new alert notifications
    const subscription = alertService.onAlert(id).subscribe((alert) => {
      // clear alerts when an empty alert is received
      if (!alert.message) {
        setAlerts((alerts) => {
          // filter out alerts without 'keepAfterRouteChange' flag
          const filteredAlerts = alerts.filter((x) => x.keepAfterRouteChange);

          // remove 'keepAfterRouteChange' flag on the rest
          return omit(filteredAlerts, "keepAfterRouteChange");
        });
      } else {
        // add alert to array with unique id
        alert.itemId = Math.random();
        setAlerts((alerts) => [...alerts, alert]);

        // auto close alert if required
        if (alert.autoClose) {
          setTimeout(() => removeAlert(alert), 3000);
        }
      }
    });

    // clear alerts on location change
    const clearAlerts = () => alertService.clear(id);
    router.events.on("routeChangeStart", clearAlerts);

    // clean up function that runs when the component unmounts
    return () => {
      mounted.current = false;

      // unsubscribe to avoid memory leaks
      subscription.unsubscribe();
      router.events.off("routeChangeStart", clearAlerts);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function omit(arr, key) {
    return arr.map((obj) => {
      const { [key]: omitted, ...rest } = obj;
      return rest;
    });
  }

  function removeAlert(alert) {
    if (!mounted.current) return;

    if (fade) {
      // fade out alert
      setAlerts((alerts) =>
        alerts.map((x) =>
          x.itemId === alert.itemId ? { ...x, fade: true } : x
        )
      );

      // remove alert after faded out
      setTimeout(() => {
        setAlerts((alerts) => alerts.filter((x) => x.itemId !== alert.itemId));
      }, 250);
    } else {
      // remove alert
      setAlerts((alerts) => alerts.filter((x) => x.itemId !== alert.itemId));
    }
  }

  function cssClasses(alert) {
    if (!alert) return;

    const classes = [
      "animate-fade-in-down px-[12px] py-[9px] mx-auto w-fit bg-red bg-white shadow-message-shadow rounded-[8px] flex items-center justify-between gap-2",
    ];

    // "animate-fade-in-down px-10 py-2 center border-l-2  border-b-2 mx-auto w-fit bg-red bg-white ",

    const alertTypeClass = {
      // [AlertType.Success]: "bg-green-200 border-green-500 text-green-700",
      // [AlertType.Error]: "bg-red-200 border-red-500 text-red-700",
      // [AlertType.Info]: "bg-yellow-200 border-yellow-500 text-yellow-700",
      // [AlertType.Warning]: "bg-yellow-200 border-yellow-500 text-yellow-700",
    };

    classes.push(alertTypeClass[alert.type]);

    return classes.join(" ");
  }

  if (!alerts.length) return null;

  return (
    <div className="text-center fixed z-50 w-full ">
      {alerts.map((alert, index) => {
        return (
          <div key={index} className={cssClasses(alert)}>
            {alert.type === "Success" && (
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="pt-1"
                color="green"
              />
            )}
            {alert.type === "Error" && (
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="pt-1"
                color="red"
              />
            )}
            <span>{alert?.message}</span>
            <FontAwesomeIcon
              className="pl-5"
              icon={faClose}
              onClick={() => removeAlert(alert)}
            />
          </div>
        );
      })}
    </div>
  );
}
