import Select from "react-select";
import React, { useState } from "react";
import Image from "next/image";
const CustomSelectField = ({
  customOptions,
  Controller,
  control,
  errors,
  fieldName,
  fieldKey,
  infoText,
  labelIcon,
  placeholder,
  customOnChange,
  defaultOnChange,
  customOninputChange,
  isClearable,
  requiredError,
  isMulti,
}) => {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <div className="my-2 text-base">
      <div className="flex">
        <p className="mb-2 text-base" htmlFor={fieldKey}>
          {fieldName}
        </p>
        {labelIcon && (
          <div className="group relative w-auto text-xs text-[#167FFC]">
            <Image
              alt="labelIcon"
              // icon={labelIcon}
              src={labelIcon}
              className="ml-2 "
              size="lg"
              onClick={() => setShowInfo(!showInfo)}
            />
            {infoText && (
              <div
                className={
                  `!group-hover:block absolute bottom-10 left-0 mb-2 hidden w-fit w-[60vw] rounded-lg bg-[#fff] py-2 px-10 shadow-lg sm:right-0 sm:w-[15vw] ${
                    showInfo ? " !block" : " !hidden sm:group-hover:!block"
                  }` + (infoText ? " left-0 sm:right-0" : " ")
                }
                htmlFor={fieldKey}
              >
                <div
                  class={
                    ` absolute bottom-[-9px] h-0 w-0 border-l-[10px] border-t-[10px] border-r-[10px] border-l-transparent border-t-[#fff] border-r-transparent sm:bottom-[-10px] ${
                      showInfo ? " !block" : " !hidden sm:group-hover:!block"
                    }` + (infoText ? " left-4 sm:right-4" : " ")
                  }
                ></div>
                {infoText}
              </div>
            )}
          </div>
        )}
      </div>
      <Controller
        name={fieldKey}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Select
            isMulti={isMulti && isMulti}
            {...field}
            options={customOptions}
            placeholder={placeholder ? placeholder : `Select ${fieldName}`}
            className={
              (isMulti ? " " : "h-[48px] ") +
              "rounded-lg  border-[1px] p-1" +
              (errors?.[`${fieldKey}`]
                ? " border-[1px] border-red-500 focus:border-red-500 focus:outline-none"
                : " border-[#CBD5E1]")
            }
            onChange={(e) => {
              if (customOnChange) {
                defaultOnChange && field.onChange(e);
                customOnChange(e);
              } else {
                field.onChange(e);
              }
            }}
            onInputChange={(e) => {
              customOninputChange && customOninputChange(e);
            }}
            backspaceRemovesValue
            isClearable={isClearable ? true : false}
          />
        )}
      />

      {errors?.[`${fieldKey}`]?.type === "required" && (
        <p role="alert" className="pt-2 text-red-500">
          {requiredError
            ? requiredError
            : ` ${fieldName.toLocaleLowerCase()} required`}
        </p>
      )}
    </div>
  );
};
export default CustomSelectField;
