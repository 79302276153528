import React from "react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomButton from "./CustomFormElements/CustomButton";

const CustomModal = ({
  title,
  isClosable,
  onClose,
  open,
  children,
  onOk,
  footer,
  okText,
  cancelText,
  customFooter,
  className,
  backgroundConfetti,
}) => {
  return (
    <div
      id="myModal"
      class={
        "modal fixed left-0 top-0 z-50 mx-auto flex h-full w-full items-center overflow-auto bg-[#fff] bg-[#0000004d]" +
        (open ? " backdrop-blur" : " hidden") +
        (backgroundConfetti ? " bg-bg_confitte bg-cover" : " ")
      }
    >
      <div
        class={
          "modal-content mx-auto w-[90vw] rounded-lg bg-[#fefefe] p-5 sm:!w-fit" +
          ` ${className}`
        }
      >
        <div
          className={
            "flex items-center justify-between gap-10 border-b-[1px] pb-2" +
            (title || isClosable ? " " : " hidden")
          }
        >
          <p className="h6">{title}</p>
          {isClosable && (
            <FontAwesomeIcon
              icon={faClose}
              onClick={onClose}
              size="xl"
              className="cursor-pointer"
            />
          )}
        </div>
        <div className="my-4">{children}</div>

        {footer && (
          <>
            {!customFooter ? (
              <div
                className={
                  "flex items-center justify-end gap-5 border-t-[1px] pt-4" +
                  (footer ? " " : " hidden")
                }
              >
                <CustomButton
                  buttonType={"outline-blue"}
                  className={"border-[1px] !py-1 px-4"}
                  onClick={onClose}
                >
                  {cancelText ? cancelText : "Cancel"}
                </CustomButton>
                <CustomButton
                  buttonType={"blue"}
                  className={"!py-1 px-4"}
                  onClick={onOk}
                >
                  {okText ? okText : "Ok"}
                </CustomButton>
              </div>
            ) : (
              <customFooter />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CustomModal;
