import servcies from "../public/images/dashboardIcons/services.png";
import dashboard from "../public/images/dashboardIcons/dashboard.png";
import profile from "../public/images/dashboardIcons/profile.png";
import aboutUsInActive from "../public/images/dashboardIcons/inActiveIcons/aboutUsInActive.png";
import servicesInActive from "../public/images/dashboardIcons/inActiveIcons/servicesInActive.png";
import dashboardInActive from "../public/images/dashboardIcons/inActiveIcons/dashboardInactive.png";
import productsInActive from "../public/images/dashboardIcons/inActiveIcons/productsInActive.png";
import productsActive from "../public/images/dashboardIcons/activeIcons/productsActive.png";
import servicesActive from "../public/images/dashboardIcons/activeIcons/servicesActive.png";
import contactUsInActive from "../public/images/dashboardIcons/inActiveIcons/contactUsInactive.png";
import faqInActive from "../public/images/dashboardIcons/inActiveIcons/faqInActive.png";
import tcInActive from "../public/images/dashboardIcons/inActiveIcons/tcInActive.png";
import myAppInActive from "../public/images/dashboardIcons/inActiveIcons/myAppInActive.png";
import businessDashboard from "../public/images/dashboardIcons/businessDashboard.svg";
import homeDashboard from "../public/images/dashboardIcons/homeDashboard.svg";
import personalDashboard from "../public/images/dashboardIcons/personalDashboard.svg";
import helpCenterInActive from "../public/images/dashboardIcons/inActiveIcons/helpCenterInActive.png";
import dashboardActive from "../public/images/dashboardIcons/activeIcons/dashboardActive.png";
import ProfileActive from "../public/images/dashboardIcons/ProfileActive.png";
import myAppActive from "../public/images/dashboardIcons/activeIcons/myAppActive.png";
import loans from "../public/images/dashboardIcons/loans.svg";

export const USER = "user";
export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";

export function convertNumberToIndianFormat(num) {
  if (num >= 10000000) {
    return (num / 10000000)?.toFixed(2) + " Crore";
  } else if (num >= 100000) {
    return (num / 100000)?.toFixed(2) + " Lacs";
  } else if (num >= 1000) {
    return (num / 1000)?.toFixed(2) + " Thousand";
  } else {
    return num?.toFixed(2);
  }
}
export function addCommas(number) {
  return number?.toLocaleString("hi");
}

export function convertMonthsToYears(months) {
  const years = months / 12;
  return years?.toFixed(1);
}
export const bankNames = {
  axis: "Axis",
  icici: "ICICI",
  hdfc: "HDFC",
  indusind: "Indusind",
  kotak: "Kotak",
  yes: "Yes",
  bob: "Bob",
  indian: "Indian",
  sbi: "Sbi",
  // idfc: "IDFC",
};
export const plemploymentTypeOptions = [
  { value: "salaried", label: "Salaried" },
  { value: "selfEmployed", label: "Self Employed-Professional" },
  { value: "business", label: "Business Owner" },
];

export const creditCardOptions = [
  { value: "salaried", label: "Salaried" },
  { value: "nonSalaried", label: "Non-salaried" },
];
export const creditCardUseOptions = [
  { value: "travel", label: "Travel" },
  { value: "cashback", label: "Cashback" },
  { value: "shopping", label: "Shopping" },
  { value: "fuel", label: "Fuel" },
  { value: "rewards", label: "Rewards" },
];
export const employmentTypeOptions = [{ value: "salaried", label: "Salaried" }];
export const blemploymentTypeOptions = [
  { value: "selfEmployed", label: "Self Employed-Professional" },
  { value: "business", label: "Business Owner" },
];

export const bankOptions = [
  { value: "icici", label: "ICICI" },
  { value: "axis", label: "Axis Bank" },
  { value: "hdfc", label: "HDFC Bank" },
  { value: "kotak", label: "Kotak Bank" },
  { value: "yes", label: "Yes Bank" },
];
export const yesORnoOptions = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];
export const toggleYesOrNoOptions = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];
export const plcompanyTypeOptions = [
  { value: "public/private", label: "Public/private Ltd" },
  // { value: "private", label: "Private Ltd" },
  { value: "partnership", label: "Partnership" },
  { value: "proprietorship", label: "Propriteryship firm" },
  { value: "llp", label: "LLP" },
  { value: "govt", label: "Government Employee" },
  { value: "trust", label: "Trust" },
  { value: "ngo", label: "NGO" },
];
export const hmlncompanyTypeOptions = [
  { value: "public_or_private_ltd", label: "Public/private Ltd" },
  {
    value: "partnership_or_proprietorship_firm_or_llp",
    label: "Partnership/Proprietorship/LLP",
  },
  // { value: "proprietorship", label: "Propriteryship firm" },
  // { value: "llp", label: "LLP" },
  { value: "govt_employee", label: "Government Employee" },
  { value: "trust_or_ngo", label: "Trust/NGO" },
];
export const blcompanyTypeOptions = [
  { value: "qualified_ca_cs_icwa", label: "Qualified CA. CS, ICWA" },
  { value: "doctor", label: "Doctor" },
  { value: "lawyer", label: "Lawyer" },
  { value: "architect", label: "Architect" },
  {
    value: "freelancer_consultant_others",
    label: "Freeleancer/Consultant/Other",
  },
];

export const plProfessionalOptions = [
  { value: "qualified_ca_cs_icwa", label: "Qualified CA" },
  { value: "doctor", label: "Doctor" },
  { value: "lawyer", label: "Lawyer" },
  { value: "architect", label: "Architect" },
  {
    value: "freelancer_consultant_others",
    label: "Freeleancer/Consultant/Other",
  },
];
export const plBusinessTypeOptions = [
  { value: "proprietor", label: "Prorietor" },
  {
    value: "director_public_or_pvt_ltd_company",
    label: "Director in public/Pvt. Ltd Company",
  },
  {
    value: "partner_in_partnership_firm_or_llp",
    label: "Partner in Partnership Firm / LLP",
  },
  // { value: "LLP", label: "LLP" },
];
export const industyTypeOptions = [
  { value: "it", label: "IT" },
  { value: "fmgc", label: "FMGC" },
  { value: "pvt_bank", label: "Private Bank" },
  { value: "manufacturing", label: "Manufacturing" },
  { value: "psu", label: "PSU" },
  { value: "state_govt", label: "State Govt" },
  { value: "central_govt", label: "Central Govt" },
  { value: "defence", label: "Defence" },
];
export const tenureTypeOptions = [
  { value: "less_than_one_year", label: "Less than 1 Year" },
  { value: "one_to_three_years", label: "1-3 Years" },
  { value: "three_to_five_years", label: "3-5 Years" },
  { value: "greater_than_five_years", label: "5+ years" },
];
export const experienceOptions = [
  { value: "less_than_six_months", label: "0-6 months" },
  { value: "six_to_twelve_months", label: "6-12 months" },
  { value: "one_to_two_years", label: "1-2 Years" },
  { value: "two_to_five_years", label: "2-5 years" },
  { value: "greater_than_five_years", label: "5+ years" },
];
export const stayOptions = [
  { value: "less_than_three_months", label: "Less than 3 months" },
  { value: "three_to_six_months", label: "3-6 months" },
  { value: "six_to_twelve_months", label: "6-12 months" },
  { value: "one_to_three_years", label: "1-3 Years" },
  { value: "greater_than_three_years", label: "3+ years" },
];
export const vintageOptions = [
  { value: "less_than_three_months", label: "Less than 3 months" },
  { value: "three_to_six_months", label: "3-6 months" },
  { value: "six_to_twelve_months", label: "6-12 months" },
  { value: "one_to_three_years", label: "1-3 Years" },
  { value: "three_to_five_years", label: "3-5 years" },
  { value: "greater_than_five_years", label: "5+ years" },
];
export const loanTimeOptions = [
  { value: "within_one_month", label: "Less than one month" },
  { value: "one_to_three_months", label: "1-3 months" },
  { value: "three_to_six_months", label: "3-6 months" },
  { value: "greater_than_six_months", label: "6+ months" },
];
export const residenceTypeOptions = [
  { value: "owned", label: "Owned" },
  { value: "rented", label: "Rented" },
];
export const hlresidenceTypeOptions = [
  { value: "owned", label: "Owned" },
  { value: "rented", label: "Rented" },
];
export const blBusinessTypeOptions = [
  { value: "proprietor", label: "Prorietor" },
  {
    value: "director_public_or_pvt_ltd_company",
    label: "Director in public/Pvt. Ltd Company",
  },
  {
    value: "partner_in_partnership_firm_or_llp",
    label: "Partner in Partnership Firm/LLP",
  },
];
export const loanTypes = [
  {
    title: "Personal Loan",
    href: "personal",
  },
  {
    title: "Home Loan",
    href: "home",
  },
  {
    title: "Business Loan",
    href: "business",
  },
  // {
  //   title: "Gold Loan",
  //   href: "gold",
  //   disable: true,
  // },
  // {
  //   title: "Loan transfer",
  //   href: "loantransfer",
  //   disable: true,
  // },
];

export const loanTransfer = [
  {
    title: "Personal Loan Transfer",
    href: "personaltransfer",
  },
  {
    title: "Home Loan Transfer",
    href: "hometransfer",
  },
];

export const loanTransferEmploymentTypeOptions = [
  { value: "salaried", label: "Salaried" },
  { value: "self_employed_professional", label: "Self Employed-Professional" },
  { value: "business_owner", label: "Business Owner" },
];

export const SelectCompanyTypeOptions = [
  {
    value: "public_or_pvt_ltd",
    label: "Public or Private Ltd",
  },
  {
    value: "partner_or_ppp_firm_or_llp",
    label: "Partnership or Proprietorship Firm or LLP",
  },
  { value: "govt_employee", label: "Government Employee" },
  {
    value: "trust_or_ngo",
    label: "Trust or NGO",
  },
];

export const IndustryCategoryOptions = [
  {
    value: "it",
    label: "It",
  },
  {
    value: "fmgc",
    label: "FMGC",
  },
  {
    value: "private_bank",
    label: "Private Bank",
  },
  {
    value: "manufacuturing",
    label: "Manufacuturing",
  },
  {
    value: "psu",
    label: "PSU",
  },
  {
    value: "state_gov",
    label: "State Govt",
  },
  {
    value: "central_gov",
    label: "Central Govt",
  },
  { value: "defence", label: "Defence" },
];

export const typeOfProfessionalOptions = [
  {
    value: "qualified_ca",
    label: "Qualified CA, CS, ICWA",
  },
  {
    value: "doctor",
    label: "Doctor",
  },
  {
    value: "lawyer",
    label: "Lawyer",
  },
  {
    value: "architect",
    label: "Architect",
  },
  {
    value: "freelancer_consultant_other",
    label: "Freelancer/ Consultant/ Other",
  },
];
export const latestBlogData = [
  {
    blog_type: "Bank",
    date: "MAY 24, 2022",
    blog_description:
      "SBI raises MCLR rates across tenors by 15 bps; loan EMI burden to increase",
  },
  {
    blog_type: "Bank",
    date: "JUN 16, 2022",
    blog_description: "Foreign banks eye bilateral settlement arrangements",
  },
  {
    blog_type: "Bank",
    date: "JUL 18, 2022",
    blog_description:
      "Jana SFB hikes deposit rates offer up to 7% on savings accounts & 8.35% on FDs",
  },
];

export const loanAmountOptions = [
  {
    value: "upto_X_lacs",
    label: "Upto 'X' lacs",
  },
  {
    value: "a_lac-b_lac",
    label: "'a' lacs - 'b' lacs",
  },
  {
    value: "c_lac-d_lac",
    label: "'c' lacs - 'd' lacs",
  },
  {
    value: "g_lac_plus",
    label: "'g' lac+",
  },
];
export const planningLoanoptions = [
  {
    value: "within_days",
    label: "Within 'X' days",
  },
  {
    value: "a_b_days",
    label: "'aa' - 'bb' days",
  },
  {
    value: "c_d_days",
    label: "'cc' - 'dd' days",
  },
  {
    value: "e_days",
    label: "'ee' days +",
  },
];

export const propertyCityOptions = [
  {
    value: "search_by_typing",
    label: "Search by typing",
  },
  {
    value: "",
    label: "Cities",
  },
];
export const primaryBankOptions = [
  {
    value: "check",
    label: "Check",
  },
  {
    value: "cash",
    label: "Cash",
  },
  {
    value: "most_selected_bank",
    label: "Most Selected Bank",
  },
  {
    value: "other_banks",
    label: "Other Banks",
  },
];

export const businessOptions = [
  {
    value: "proprietor",
    label: "Proprietor",
  },
  {
    value: "director_in_public",
    label: "Director in Public / Pvt. Ltd. Company",
  },
  {
    value: "partner_partnership",
    label: "Partner in Partnership Firm / LLP",
  },
];
export const plestablishmentTypeOptions = [
  { value: "office", label: "Office" },

  { value: "shop", label: "Shop" },
];
export const businessCategoryOptions = [
  {
    value: "wholesale",
    label: "Wholesale",
  },
  {
    value: "retail",
    label: "Retail",
  },
  {
    value: "manufacturing",
    label: "Manufacturing",
  },
  {
    value: "services",
    label: "Services",
  },
];

export const genderData = [
  { label: "Male", value: "male" },
  {
    label: "Female",
    value: "female",
  },
  { label: "Others", value: "others" },
];
export const fingoServices = [
  {
    title: "Personal Loan",
    href: "/loan?type=personal",
    body: "Avail Personal loans instantly @ lowest interest rates from top banks to meet your financial needs.",
    fingoServicesApplyNow: "Apply Now",
    src: "personal",
  },
  // {
  //   title: "Education Loan",
  //   href: "/loan?type=education",
  //   disable: true,
  //   body: "Start your new beginnings with a flexible education loan at ease.",
  // },
  {
    title: "Business Loan",
    href: "/loan?type=business",
    body: "Get a hassle-free business loan and scale up your business to new heights. ",
    fingoServicesApplyNow: "Apply Now",
    src: "business",
  },
  {
    title: "Home Loan",
    href: "/loan?type=home",
    body: "Build your Dream home with customised home loan offers from our top partner banks.",
    fingoServicesApplyNow: "Apply Now",
    src: "home",
  },
  {
    title: "Credit Score",
    href: "/loan?type=credit",
    body: "Check your Credit Score for Free and unlock personalised loan offers",
    fingoServicesApplyNow: "Check Now",
    // disable: true,
    src: "credit-score",
  },
  {
    title: "Credit Card",
    href: "/loan?type=creditcard",
    body: "Check your Credit Score for Free and unlock personalised loan offers",
    fingoServicesApplyNow: "Apply Now",
    // disable: true,
    src: "credit",
  },
  {
    title: "EMI Calculator",
    href: "/calculator?emitype=emi",
    // disable: true,
    body: "Check your EMI with a click",
    fingoServicesApplyNow: "Calculate Now",
    src: "calculator",
  },
  // {
  //   title: "Gold Loan",
  //   href: "/loan?type=gold",
  //   disable: true,
  //   body: "Start your new beginnings with a flexible Gold loan at ease.",
  // },
  // {
  //   title: "Vehicle Loan",
  //   href: "/loan",
  //   disable: true,
  //   body: "Start your new beginnings with a flexible Vehicle loan at ease.",
  // },
  // {
  //   title: "Credit Card",
  //   href: "/loan",
  //   disable: true,
  //   body: "Start your new beginnings with a flexible Credit Card at ease.",
  // },
  // {
  //   title: "Property Loan",
  //   href: "/loan",
  //   disable: true,
  //   body: "Start your new beginnings with a flexible Property loan at ease.",
  // },
];
export const authFingoServices = [
  {
    title: "Personal Loan",
    href: "/products/loans?loantype=personal",
    body: "Avail Personal loans instantly @ lowest interest rates from top banks to meet your financial needs.",
    src: "personal",
  },
  // {
  //   title: "Education Loan",
  //   href: "/products/loans?loantype=education",
  //   disable: true,
  //   body: "Start your new beginnings with a flexible education loan at ease.",
  // },Click below
  {
    title: "Business Loan",
    href: "/products/loans?loantype=business",
    body: "You are just a click away from a hassle-free business loan to scale up your business to new heights.",
    src: "business",
  },
  {
    title: "Home Loan",
    href: "/products/loans?loantype=home",
    body: "Do not compromise on your Dream home. Get a customized home loan that matches your needs.",
    src: "home",
  },
  // {
  //   title: "Credit Score",
  //   href: "/checkcredit",
  //   body: "Check your free credit score",
  //   src: "credit-score",
  // },
  {
    title: "EMI Calculator",
    href: "/calculator",
    // disable: true,
    body: "Calculate EMI on your loans",
    src: "calculator",
  },
  {
    title: "Credit Card",
    href: "creditcard",
    body: "Check your free credit score",
    src: "credit",
  },
  // {
  //   title: "Gold Loan",
  //   href: "/products/loans?loantype=gold",
  //   disable: true,
  //   body: "Start your new beginnings with a flexible Gold loan at ease.",
  // },
  // {
  //   title: "Vehicle Loan",
  //   href: "/products/loans?loantype=vehicle",
  //   disable: true,
  //   body: "Start your new beginnings with a flexible Vehicle loan at ease.",
  // },
  // {
  //   title: "Credit Card",
  //   href: "/products/loans?loantype=credit",
  //   disable: true,
  //   body: "Start your new beginnings with a flexible Credit Card at ease.",
  // },
  // {
  //   title: "Property Loan",
  //   href: "/products/loans?loantype=property",
  //   disable: true,
  //   body: "Start your new beginnings with a flexible Property loan at ease.",
  // },
];
export const aboutUsServices = [
  {
    title: "Personal Loan",
    href: "/loan?type=personal",
    body: "Avail Personal loans instantly @ lowest interest rates from top banks to meet your financial needs.",
    src: "personal",
  },
  // {
  //   title: "Education Loan",
  //   href: "/loan?loantype=education",
  //   disable: true,
  //   body: "Start your new beginnings with a flexible education loan at ease.",
  // },
  {
    title: "Business Loan",
    href: "/loan?type=business",
    body: "You are just a click away from a hassle-free business loan to scale up your business to new heights.",
    src: "business",
  },
  {
    title: "Home Loan",
    href: "/loan?type=home",
    body: "Do not compromise on your Dream home. Get a customized home loan that matches your needs.",
    src: "home",
  },
  {
    title: "Credit Score",
    href: "/loan?type=credit",
    body: "Check your free credit score",
    src: "credit-score",
  },
  {
    title: "EMI Calculator",
    href: "/calculator",
    body: "Check your EMI with a click ",
    src: "calculator",
  },
  // {
  //   title: "Gold Loan",
  //   href: "/loan?type=gold",
  //   disable: true,
  //   body: "Start your new beginnings with a flexible Gold loan at ease.",
  // },
  // {
  //   title: "Vehicle Loan",
  //   href: "/loan?type=vehicle",
  //   disable: true,
  //   body: "Start your new beginnings with a flexible Vehicle loan at ease.",
  // },
  // {
  //   title: "Credit Card",
  //   href: "/loan?type=credit",
  //   disable: true,
  //   body: "Start your new beginnings with a flexible Credit Card at ease.",
  // },
  // {
  //   title: "Property Loan",
  //   href: "/products/loan?type=property",
  //   disable: true,
  //   body: "Start your new beginnings with a flexible Property loan at ease.",
  // },
];
export const webHeaderLinks = [
  { title: "Personal Loan", href: "/loan?type=personal" },
  { title: "Business Loan", href: "/loan?type=business" },
  { title: "Home Loan", href: "/loan?type=home" },
  // {
  //   title: "Credit Cards",
  //   href: "/products?type=creditcards",
  //   disable: true,
  // },
  // {
  //   title: "Check Credit Score",
  //   href: "/products?type=credit",
  //   disable: true,
  // },
];

export const mobileHeaderLinks = [
  {
    title: "Loans & Cards",
    icon: "",
    src: loans,
    srcActive: loans,
    kids: [
      {
        title: "Personal Loan",
        href: "/loan?type=personal",
      },
      {
        title: "Business Loan",
        href: "/loan?type=business",
      },
      {
        title: "Home Loan",
        href: "/loan?type=home",
      },
      {
        title: "Credit Card",
        href: "/loan?type=creditcard",
      },
    ],
  },
  {
    title: "Resources",
    src: servicesInActive,
    srcActive: servicesActive,
    kids: [
      {
        title: "Personal Loan",
        href: "/resources?type=personal#",
      },
      {
        title: "Business Loan",
        href: "/resources?type=business#",
      },
      {
        title: "Home Loan",
        href: "/resources?type=home#",
      },
      {
        title: "Calculators",
        href: "/calculator?emitype=emi",
      },
    ],
  },
  {
    title: "Check Credit Score",
    href: "/loan?type=credit",
    src: servicesInActive,
  },

  {
    title: "About Us",
    href: "/about-us",
    src: aboutUsInActive,
  },

  {
    title: "FAQ's",
    href: "/faq",
    src: faqInActive,
  },

  {
    title: "Contact Us",
    href: "/contact-us",
    src: contactUsInActive,
  },
];
// title: "Loans", href: "/products/loans?loantype=personal"
export const mobileAuthHeaderLinks = [
  {
    title: "My Account",
    href: "/myaccount",
    src: profile,
    srcActive: ProfileActive,
    // disable: true,
  },
  {
    title: "Dashboard",
    href: "/dashboard",
    src: dashboardInActive,
    srcActive: dashboardActive,
  },
  {
    title: "Loans & Cards",
    src: servicesInActive,
    srcActive: servicesActive,
    kids: [
      {
        title: "Personal Loan",
        href: "/products/loans?loantype=personal",
      },
      {
        title: "Business Loan",
        href: "/products/loans?loantype=business",
      },
      {
        title: "Home Loan",
        href: "/products/loans?loantype=home",
      },
      {
        title: "Credit Card",
        href: "/creditcard",
      },
    ],
  },
  {
    title: "My Applications",
    href: "/applications",
    src: myAppInActive,
    srcActive: myAppActive,
    kids: [
      {
        title: "Loans",
        href: "/applications",
      },
      {
        title: "Credit Cards",
        href: "/cardapplications",
      },
    ],
  },
  {
    title: "Resources",
    src: servicesInActive,
    srcActive: servicesActive,
    kids: [
      {
        title: "Personal Loan",
        href: "/resources?type=personal#",
      },
      {
        title: "Business Loan",
        href: "/resources?type=business#",
      },
      {
        title: "Home Loan",
        href: "/resources?type=home#",
      },
      {
        title: "Calculators",
        href: "/calculator?emitype=emi",
      },
    ],
  },
  // {
  //   title: "Products",
  //   icon: "",
  //   src: productsInActive,
  //   srcActive: productsActive,

  //   kids: [
  //     {
  //       title: "Credit Score",
  //       href: "/checkcredit",
  //     },
  //     {
  //       title: "Calculators",
  //       href: "/calculator?emitype=emi",
  //     },
  //   ],
  // },

  // {
  //   title: "My Applications",
  //   href: "/applications",
  //   src: myAppInActive,
  //   srcActive: myAppActive,
  // },

  {
    title: "About Us",
    href: "/about-us",
    src: aboutUsInActive,
  },

  {
    title: "FAQ's",
    href: "/faq",
    src: faqInActive,
  },

  {
    title: "Contact Us",
    href: "/contact-us",
    src: contactUsInActive,
  },

  {
    title: "Terms of use",
    href: "/terms",
    src: tcInActive,
  },

  {
    title: "Help Centre",
    href: "/help-centre",
    src: helpCenterInActive,
    srcActive: helpCenterInActive,
  },
];
export const personalLoanTenureOptions = [
  { value: "12", label: "12 Months" },
  { value: "24", label: "24 Months" },
  { value: "36", label: "36 Months" },
  { value: "48", label: "48 Months" },
  { value: "60", label: "60 Months" },
];
// [12, 18, 24, 30, 36, 42, 48]
export const loanTenureOptions = [
  { value: "12", label: "12 Months" },
  { value: "18", label: "18 Months" },
  { value: "24", label: "24 Months" },
  { value: "30", label: "30 Months" },
  { value: "36", label: "36 Months" },
  { value: "42", label: "42 Months" },
  { value: "48", label: "48 Months" },
];
export const homeLoanTenureOptions = [
  { value: "10", label: "10 Years" },
  { value: "15", label: "15 Years" },
  { value: "20", label: "20 Years" },
  { value: "25", label: "25 Years" },
  { value: "30", label: "30 Years" },
];
export const companyOptions = [
  {
    value: "3frames software labs private limited",
    label: "3frames software labs private limited",
  },
  { value: "3 loq labs private limited", label: "3 loq labs private limited" },
  {
    value: "Aarnav fabrics private limited",
    label: "Aarnav fabrics private limited",
  },
  {
    value: "Abdos labtech private limited",
    label: "Abdos labtech private limited",
  },
];

export const loanSliderProps = [
  {
    title: "PERSONAL LOAN",
    body: "Get customised offers on your personal loan instantly",
    src: personalDashboard,
    href: "/products/loans?loantype=personal",
  },
  {
    title: "BUSINESS LOAN",
    body: "Get hassle free Business loan from our partner banks",
    src: businessDashboard,
    href: "/products/loans?loantype=business",
  },
  {
    title: "HOME LOAN",
    body: "Get customised offers on your personal loan instantly",
    src: homeDashboard,
    href: "/products/loans?loantype=home",
  },
];
export const bankTypeOptions = [
  {
    id: 1,
    value: "axis",
    label: "Axis",
  },
  {
    id: 2,
    value: "icici",
    label: "ICICI",
  },
  {
    id: 3,
    value: "hdfc",
    label: "HDFC",
  },
  {
    id: 4,
    value: "indus",
    label: "Indusind",
  },
  {
    id: 5,
    value: "kotak",
    label: "Kotak",
  },
  {
    id: 6,
    value: "yes",
    label: "Yes",
  },
  {
    id: 7,
    value: "bob",
    label: "BankofBaroda",
  },
  {
    id: 8,
    value: "indian",
    label: "Indian",
  },
];

export const fingoSocialLinks = [
  { name: "fb", href: "https://www.facebook.com/FingoFinancials" },
  { name: "insta", href: "https://www.instagram.com/fingo_india/" },
  { name: "linkedIn", href: "https://www.linkedin.com/in/fingoindia/" },
  { name: "twitter", href: "https://twitter.com/FinGo_India" },
];

export const referalLoanTypeOptions = [
  { value: "PL", label: "Personal Loan" },
  { value: "BL", label: "Business Loan" },
  { value: "HL", label: "Home Loan" },
  { value: "VL", label: "Vehicle Loan" },
  { value: "EL", label: "Education Loan" },
];
export const creditDataError = {
  E0022: "E0022",
  E0023: "E0023",
  E0024: "E0024",
  E0002: "E0002",
  E0029: "E0029",
  E0030: "E0030",
  E0406: "E0406",
};

export const creditInvalidInfo = {
  E0036: "E0036",
  E0037: "E0037",
  E0038: "E0038",
  E0001: "E0001",
  E0421: "E0421",
};
