import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useRouter } from "next/router";

import Image from "next/image";

import CustomInputField from "../../CustomComponents/CustomFormElements/CustomInputField";
import CustomModal from "../../CustomComponents/CustomModal";
import CustomSelectField from "../../CustomComponents/CustomFormElements/CustomSelectField";
import CustomButton from "../../CustomComponents/CustomFormElements/CustomButton";

import { referalLoanTypeOptions } from "../../../utils/constants";
import { postReferalFormApi } from "../../../services/referal.services";

import ReferalFormImage from "../../../public/images/Referal/referal-form.png";
import successImage from "../../../public/images/success.png";

const ReferalForm = () => {
  const [formType, setFormType] = useState("referrer");
  const [open, setOpen] = useState(false);
  const router = useRouter();

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm();

  const onSubmit = async (e) => {
    let formData = {};
    if (formType == "referrer") {
      formData = {
        ...e,
        is_referrer: true,
        referrer_loan_type: e.referrer_loan_type.value,
      };
    } else {
      formData = {
        ...e,
        is_referrer: false,
        referee_loan_type: e.referee_loan_type.value,
      };
    }

    try {
      const res = await postReferalFormApi(formData);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        router.push("/referal");
      }, 3000);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    reset();
  }, [formType]);

  return (
    <>
      <div className="mx-auto my-12 sm:w-3/4" id="form">
        <p className="my-3 text-center text-[40px] font-bold leading-[40px] text-button-pink sm:text-[60px] sm:leading-[60px]">
          It’s Easy
        </p>
        <p className="mb-3 text-center text-lg font-bold sm:text-xl">
          Refer a friend and earn up-to ₹5,750/Referral{" "}
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="col-span-2">
            <button
              className={
                "px-6 py-[6px] text-lg font-bold" +
                (formType == "referrer"
                  ? " rounded-t-lg bg-button-pink  text-white"
                  : " ")
              }
              onClick={() => setFormType("referrer")}
            >
              Myself
            </button>
            <button
              className={
                "px-6 py-[6px] text-lg font-bold" +
                (formType === "referee"
                  ? " rounded-t-lg bg-button-pink  text-white"
                  : " ")
              }
              onClick={() => setFormType("referee")}
            >
              Refer a Friend
            </button>
          </div>
          <form className="rounded-tl-0 grid h-fit grid-cols-1 gap-2 rounded-[8px] border-[1px] border-[#999999] p-6">
            {formType === "referrer" && (
              <p className="w-full border-b-[1px] border-black text-base font-bold sm:w-fit">
                Referrer Details
              </p>
            )}
            <CustomInputField
              errors={errors}
              maxLength={50}
              fieldName={"Your Name"}
              fieldKey={`referrer_full_name`}
              placeholder={"Enter Full Name"}
              Controller={Controller}
              control={control}
            />
            <CustomInputField
              errors={errors}
              fieldName={"Mobile Number"}
              fieldKey={`referrer_mobile`}
              placeholder={"Mobile Number"}
              Controller={Controller}
              control={control}
              customDataType="number"
              minLength={10}
              maxLength={10}
              prefix="+91"
            />
            {formType === "referee" && (
              <p className="w-full border-b-[1px] border-black pt-6 text-base font-bold sm:w-fit">
                Referee Details
              </p>
            )}
            {formType === "referee" && (
              <>
                <CustomInputField
                  errors={errors}
                  maxLength={50}
                  fieldName={"Referee Full Name "}
                  fieldKey={`${formType}_full_name`}
                  placeholder={"Enter Full Name"}
                  Controller={Controller}
                  control={control}
                />
                <CustomInputField
                  errors={errors}
                  fieldName={"Referee Mobile Number"}
                  fieldKey={`${formType}_mobile`}
                  placeholder={"Mobile Number"}
                  Controller={Controller}
                  control={control}
                  customDataType="number"
                  minLength={10}
                  maxLength={10}
                  prefix="+91"
                />
              </>
            )}
            <CustomSelectField
              errors={errors}
              fieldName="Loan Type"
              fieldKey={`${formType}_loan_type`}
              Controller={Controller}
              control={control}
              customOptions={referalLoanTypeOptions}
            />
            <CustomInputField
              errors={errors}
              fieldName={"Your Desired Loan Amount"}
              fieldKey={`${formType}_loan_amount`}
              prefix={"INR"}
              Controller={Controller}
              control={control}
              customDataType="number"
              maxLength={9}
            />
            <CustomButton
              type="submit"
              buttonType="pink"
              onClick={handleSubmit(onSubmit)}
              className="my-4 w-full text-base"
            >
              Submit
            </CustomButton>
          </form>
          <Image
            src={ReferalFormImage}
            className="hidden h-auto w-full place-self-center lg:block"
            alt="Referal Form Image"
          />
        </div>
      </div>
      <CustomModal
        open={open}
        className={
          "bg-bg_confitte bg-cover py-[62.62px] px-11 sm:py-16 sm:px-12"
        }
      >
        <div className="flex h-full w-full flex-col items-center justify-center text-center">
          <Image src={successImage} alt="success" className="mb-10" />
          <p className="text-xl font-bold">Thank you!.</p>
          <p>Your submission has been sent.</p>
        </div>
      </CustomModal>
    </>
  );
};

export default ReferalForm;
