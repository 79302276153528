import React from "react";
import Head from "next/head";

function SeoMeta() {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || "";
  const bannerUrl = `${baseUrl}/images/Banner/Banner.png`;

  const metadata = {
    url: baseUrl,
    applicationName: "FingoIndia",
    description:
      "Your One-Stop Financial Marketplace for Loans, Credit Cards, and More. Get personalized assistance with our partner banks, and enjoy zero cost service",
    keywords: [
      "Business loans in India",
      "Personal loans online",
      "Credit card score improvement",
      "Fingo Financial Technologies",
      "Fast loan approvals",
      "Loan options in India",
      "Best loan rates",
      "Partnership with banks in India",
      "Online loan application process",
      "Loan eligibility criteria",
      "Unsecured loans",
      "CIBIL score",
      "Free CIBIL score",
      "Home loans with low interest rates",
    ].join(", "),
    image: {
      url: bannerUrl,
      alt: "Banner",
    },
  };

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>{metadata.applicationName}</title>
      <meta name="description" content={metadata.description} key="desc" />{" "}
      <meta name="keywords" content={metadata.keywords} />
      <meta property="og:locale" content="en" />
      <meta property="og:title" content={metadata.applicationName} />
      <meta property="og:description" content={metadata.description} />
      <meta property="og:url" content={metadata.url} />
      <meta property="og:site_name" content={metadata.applicationName} />
      <meta property="og:image" content={metadata.image.url} />
      <meta property="og:image:secure" content={metadata.image.url} />
      <meta property="og:type" content="website" />
      {/* <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-M8DLEFYN32"
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-M8DLEFYN32');
          `,
        }}
      /> */}
    </Head>
  );
}

export default SeoMeta;
