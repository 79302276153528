import React, { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";

const CustomDropDownMenu = ({ title, kids }) => {
  const [openMenu, setOpenMenu] = useState(false);

  const timeoutRef = useRef(null);

  const handleOpen = () => {
    setOpenMenu(true);
  };

  const handleClose = () => {
    timeoutRef.current = setTimeout(() => {
      setOpenMenu(false);
    }, 200);
  };

  return (
    <div className="relative inline-block w-fit text-left">
      <div className="" onClick={() => setOpenMenu(!openMenu)}>
        <button
          type="button"
          className={
            "text-base hover:text-nav-blue" +
            (openMenu ? " font-bold text-nav-blue" : "")
          }
          onClick={handleOpen}
          onMouseLeave={handleClose}
        >
          {title}
        </button>
        <FontAwesomeIcon
          icon={openMenu ? faAngleUp : faAngleDown}
          className="ml-3"
        />
      </div>

      {openMenu && (
        <div
          className="rounded-nav-menu absolute right-0 top-[44px] z-10 rounded-b-2xl border border-t-0 border-[#CBD5E1] bg-white text-center"
          onMouseEnter={() => clearTimeout(timeoutRef.current)}
          onMouseLeave={handleClose}
        >
          {kids?.map((eachComp, index) => {
            return (
              <div
                className={
                  "flex h-full w-full justify-between gap-4 border-[#b6b6ff33] py-3 pl-10 pr-12 text-black hover:border-y-[1px] hover:bg-[#2927991a] hover:text-nav-blue" +
                  (kids && index === kids?.length - 1
                    ? " rounded-b-2xl !border-b-0"
                    : "")
                }
                key={index}
              >
                <Image
                  src={eachComp.src}
                  alt={eachComp.src}
                  className=" h-[26px] w-[26px]"
                  width={26}
                  height={26}
                />
                <Link
                  href={eachComp.href}
                  onClick={() => {
                    setOpenMenu(!openMenu);
                  }}
                  className={"body3 whitespace-nowrap pr-5"}
                  role="menuitem"
                  tabIndex="-1"
                  id={`menu-item-1`}
                >
                  {eachComp.title}
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CustomDropDownMenu;
