import React, { useEffect } from "react";
import Head from "next/head";
import HeaderFooterLayout from "../components/Layout/HeaderFooterLayout";
import Authentication from "../components/Layout/Authentication";
import Alert from "../components/CustomComponents/CustomAlert";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";
import { clear, getItem } from "../utils/localstorage";
import Script from "next/script";
import { USER } from "../utils/constants";
import { logoutApi } from "../services/auth.services";
import useIdle from "../utils/useIdleTimerHook";
import { useRouter } from "next/router";
import "../styles/globals.css";
import Banner from "../public/images/Banner/Banner.png";
import GoogleAnalytics from "../components/GoogleAnalytics";
import SeoMeta from "../components/SeoMeta";

config.autoAddCss = false; /* eslint-disable import/first */

const Empty = ({ children }) => {
  return <div className="bg-white">{children}</div>;
};
function MyApp({ Component, pageProps, ...appProps }) {
  const LayoutComponent = getLayout(appProps?.router?.pathname);
  const router = useRouter();

  function getLayout(route) {
    switch (route) {
      case "/verify-email":
      case "/google-success":
      case "/login":
      case "/setprofile":
      case "/set-password":
      case "/session-expired":
      case "/404":
        return Empty;
      default:
        return HeaderFooterLayout;
    }
  }

  const logoutFunc = async () => {
    try {
      await logoutApi();
      router.push("/session-expired");
    } catch (error) {
      console.log("error", error);
    }
  };

  const logout = async () => {
    try {
      let userDetails = getItem(USER, true);
      if (userDetails) {
        await logoutFunc();
        clear();
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    // create broadcast channel to recive data
    const channel = new BroadcastChannel("myChannel");

    channel.onmessage = (event) => {
      console.log(event.data);
      if (
        event?.data?.data === "Another session opened" &&
        sessionStorage.getItem("loggedin")
      ) {
        logout();
        router.push("/session-expired");
      }
    };
    return () => {
      // close broadcast channels
      channel.close();
    };
  }, []);

  function disableBackButton() {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
  }
  ///for disbaling back button
  useEffect(() => {
    const userDetails = getItem(USER, true);
    userDetails && disableBackButton();

    return () => {
      window.onpopstate = null;
    };
  }, [router]);

  /// user inactivity for 15 minss
  const { isIdle } = useIdle({ onIdle: logout, idleTime: 15 });
  return (
    <>
      <SeoMeta />
      {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_API_KEY ? (
        <GoogleAnalytics
          ga_id={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_API_KEY}
        />
      ) : null}
      <div className="relative !font-roboto text-xs leading-4 sm:text-base">
        <Alert />
        <LayoutComponent>
          <Authentication>
            <Component />
          </Authentication>
        </LayoutComponent>
      </div>
      {/* http://js-na1.hs-scripts.com/24190513.js */}
      <Script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js-na1.hs-scripts.com/24190513.js"
      ></Script>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-M8DLEFYN32"
        strategy="afterInteractive" // Loads the script right after the page becomes interactive
      />
      <Script id="gtag-init" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-M8DLEFYN32', {
            page_path: window.location.pathname,
          });
      `}
      </Script>
    </>
  );
}

export default MyApp;
