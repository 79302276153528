import React, { useState, useEffect } from "react";
import Link from "next/link";
import Image from "next/image";

import {
  faBars,
  faAngleUp,
  faAngleDown,
  faArrowRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomButton from "../CustomComponents/CustomFormElements/CustomButton";
import CustomDropDownMenu from "../CustomComponents/CustomDropDownMenu";
import Logo from "../../public/images/Logo.png";
import { clear } from "../../utils/localstorage";
import { alertService } from "../../utils/alert.service";
import { logoutApi } from "../../services/auth.services";

const Navbar = ({ setShowdrawer, user, router }) => {
  const [openmenu, setOpenMenu] = useState(false);

  const logoutFunc = async () => {
    try {
      const resp = await logoutApi();
      clear();
      setOpenMenu(!openmenu);
      alertService.success("Logged Out Successfully!!", {
        keepAfterRouteChange: true,
      });
      router.push("/");
    } catch (error) {
      console.log("error", error);
      alertService.error(error?.response?.data?.message, {
        keepAfterRouteChange: true,
      });
    }
  };

  const webHeaderLinks = [
    {
      title: "Loans & Cards",
      kids: [
        {
          title: "Personal Loan",
          href: "/loan?type=personal",
          src: "/images/servicesIcons/personal.svg",
        },
        {
          title: "Business Loan",
          href: "/loan?type=business",
          src: "/images/servicesIcons/business.svg",
        },
        {
          title: "Home Loan",
          href: "/loan?type=home",
          src: "/images/servicesIcons/home.svg",
        },
        {
          title: "Credit Card",
          href: "/loan?type=creditcard",
          src: "/images/servicesIcons/credit.svg",
        },
      ],
    },
    // {
    //   title: "Products",
    //   kids: [
    //     {
    //       title: "Credit Score",
    //       href: "/loan?type=credit",
    //       src: "/images/servicesIcons/credit-score.png",
    //     },
    //     {
    //       title: "Calculators",
    //       href: "/calculator",
    //       src: "/images/servicesIcons/calculator.png",
    //     },
    //   ],
    // },
    {
      title: "Check Credit Score",
      href: "/loan?type=credit",
      // src: "/images/servicesIcons/business.png",
    },
    {
      title: "Resources",
      kids: [
        {
          title: "Personal Loan",
          href: "/resources?type=personal#",
          src: "/images/servicesIcons/personal.svg",
        },
        {
          title: "Business Loan",
          href: "/resources?type=business#",
          src: "/images/servicesIcons/business.svg",
        },
        {
          title: "Home Loan",
          href: "/resources?type=home#",
          src: "/images/servicesIcons/home.svg",
        },
        {
          title: "Calculators",
          href: "/calculator",
          src: "/images/servicesIcons/calculator.svg",
        },
      ],
    },
  ];

  return (
    <>
      <nav
        className={`sticky top-0 z-10 border-[#E4E4E7] bg-white  sm:h-[64px] sm:border-0  sm:shadow-nav-shadow`}
      >
        <div className="mx-auto flex w-[94vw] items-center justify-between py-[12px] sm:w-[90vw] sm:py-[14px]">
          <Link href={user ? "/dashboard" : "/"}>
            <Image
              src={Logo}
              alt="logo"
              className={
                "h-[30px] w-auto" +
                (!user ? " xl:text-center" : " lg:text-center")
              }
            />
          </Link>
          <div className="flex items-center gap-4">
            <CustomButton
              buttonType={"blue"}
              className={
                "place-self-end py-[6px] px-2 text-xs sm:hidden sm:text-sm" +
                (!user ? " " : " hidden")
              }
              onClick={() => router.push("/referal")}
            >
              Refer Now
            </CustomButton>
            <FontAwesomeIcon
              icon={faBars}
              size="2xl"
              className={" lg:!hidden"}
              onClick={() => setShowdrawer(true)}
            />
          </div>

          {!user && (
            <div className="hidden items-center gap-[30px] lg:flex">
              {webHeaderLinks.map((l, index) => {
                return (
                  <>
                    {l?.kids ? (
                      <CustomDropDownMenu
                        key={l.title}
                        title={l?.title}
                        kids={l?.kids}
                        openmenu={l.openmenu}
                        setOpenMenu={l.setOpenMenu}
                      />
                    ) : (
                      <p
                        onClick={() => router.push(l?.href)}
                        className={
                          "cursor-pointer hover:text-nav-blue " +
                          (router.asPath == l.href
                            ? " font-bold text-nav-blue "
                            : "")
                        }
                      >
                        {l.title}{" "}
                      </p>
                    )}
                  </>
                );
              })}

              <CustomButton
                buttonType={"outline-blue"}
                onClick={() => router?.push("/login")}
                className="button-medium ml-[8vw] py-1 transition delay-200 duration-200 hover:bg-nav-blue hover:text-white sm:px-[18px] sm:py-[7px]"
              >
                Sign In
              </CustomButton>
              <CustomButton
                buttonType={"blue"}
                className={
                  "hidden place-self-end px-2 text-xs sm:block sm:py-2 sm:px-4 sm:text-sm"
                }
                onClick={() => router.push("/referal")}
              >
                Refer Now
              </CustomButton>
            </div>
          )}

          {user && (
            // user profile menu in large screens
            <>
              <div className="hidden text-left lg:block">
                <div
                  className="relative hidden md:inline-block"
                  onClick={() => setOpenMenu(!openmenu)}
                >
                  <button
                    type="button"
                    className="h-9 w-9 rounded-full bg-black text-lg font-medium text-white"
                    id="menu-button"
                    aria-expanded="true"
                    aria-haspopup="true"
                  >
                    {user?.full_name
                      ? user?.full_name?.charAt(0).toUpperCase()
                      : "U"}
                  </button>
                  <FontAwesomeIcon
                    icon={openmenu ? faAngleUp : faAngleDown}
                    className="ml-3"
                  />
                </div>

                {openmenu && (
                  <div
                    className="absolute top-[60px] right-5 z-10 mt-2 origin-top-right  rounded-[10px] bg-white px-7 py-6 text-left shadow-account-shadow md:right-[8vw]"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabIndex="-1"
                    onMouseLeave={() => setOpenMenu(!openmenu)}
                  >
                    <Link
                      href="/myaccount"
                      className="text-md block  text-gray-700"
                      role="menuitem"
                      tabIndex="-1"
                      id={`menu-item-1`}
                    >
                      My Account
                    </Link>
                    <Link
                      href="/help-centre"
                      className="text-md mt-4 block text-gray-700"
                      role="menuitem"
                      tabIndex="-1"
                      id={`menu-item-1`}
                    >
                      Help Centre
                    </Link>

                    <button
                      type="submit"
                      className="block w-full pt-4 text-left text-base font-medium text-button-pink"
                      role="menuitem"
                      tabIndex="-1"
                      id="menu-item-3"
                      onClick={() => {
                        logoutFunc();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faArrowRightFromBracket}
                        className="mr-2"
                      />
                      Logout
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {["/loan"].includes(router.pathname) && (
          <div className="flex w-full items-center bg-button-pink p-2 text-center text-xs font-medium text-white">
            <marquee
              className=""
              direction="left"
              width="100%"
              scrollamount="6"
            >
              <span className="mr-[6px] w-fit rounded-[2px] bg-[#10B981] p-1">
                Free
              </span>
              Our services are provided free of charge, with no fees invovled.
            </marquee>
          </div>
        )}
      </nav>
    </>
  );
};
export default Navbar;
