import {
  faArrowRight,
  faArrowRightFromBracket,
  faArrowUpFromBracket,
  faRightToBracket,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import Logo from "../../public/images/Logo.png";
import { alertService } from "../../utils/alert.service";
import {
  mobileAuthHeaderLinks,
  mobileHeaderLinks,
} from "../../utils/constants";
import { clear } from "../../utils/localstorage";
import { modifiedDateTime } from "../../utils/utility";
import CustomButton from "../CustomComponents/CustomFormElements/CustomButton";
import DrawerMenu from "./Drawer/DrawerMenu";

function SideDrawer({ setShowdrawer, showDrawer, user, router }) {
  return (
    <div
      className={
        "bg-white h-login w-screen fixed top-0 right-0 z-40 px-3 text-right ease-in-out duration-500" +
        (showDrawer ? " translate-x-0" : " translate-x-full")
      }
    >
      <div className="text-right flex w-full justify-between items-center pb-6">
        <Link href="/" className="py-3">
          <Image
            src={Logo}
            alt="logo"
            className={
              "w-[120px]" + (!user ? " xl:text-center" : " lg:text-center")
            }
          />
        </Link>

        <FontAwesomeIcon
          icon={faClose}
          size="2xl"
          onClick={() => {
            setShowdrawer(false);
          }}
          className=""
        />
      </div>
      <div className="text-left w-full text-xs pb-6">
        {user?.last_login &&
          `Last login time: ${modifiedDateTime(user?.last_login)}`}
      </div>
      <div className="h-[70%] overflow-y-scroll">
        <DrawerMenu data={user ? mobileAuthHeaderLinks : mobileHeaderLinks} />
      </div>

      <div>
        {user ? (
          <CustomButton
            buttonType="pink"
            className="w-[94vw] absolute bottom-3 left-3"
            onClick={() => {
              clear();
              setShowdrawer(!showDrawer);
              alertService.success("Logged Out Successfully!!", {
                keepAfterRouteChange: true,
              });
              router.push("/");
            }}
          >
            Logout 
            <FontAwesomeIcon icon={faArrowUpFromBracket} rotation={270} className="mx-2" />
            
          </CustomButton>
        ) : (
          <CustomButton
            buttonType="pink"
            className="w-[94vw] absolute bottom-3 left-3"
            onClick={() => router.push("/login")}
          >
            Sign In
            <FontAwesomeIcon icon={faRightToBracket} size="xs" className="pl-2" />
          </CustomButton>
        )}
      </div>
    </div>
  );
}

export default SideDrawer;
